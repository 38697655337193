import {TextField} from '@cmsgov/design-system'
import React from 'react'
import {maskValue, validateField} from '../../../utilities/field-input/validations'
import CreateLabel from '../InputLabel/InputLabel'

interface Props{
  type: string,
  inputId: string,
  label: string,
  ariaLabel?: string,
  placeholder?: string,
  required?: boolean,
  length?: number,
  min?: number,
  max?: number,
  maxLength: number,
  disabled?: boolean,
  className?: string,
  value?: string,
  errorMessage?: string,
  errorText?: string,
  onError?: (errorMessage:string)=>void,
  onUpdate?: (id:string, value:number, error?:string, eventType?:string, hasMaxLength?: boolean) =>  void
  onFocusLost: (id:string, value:number, error?:string, eventType?:string) =>  void
}

interface State{
  pattern: string,
  label: string,
  maxLength?: number
}

export class NumericInput extends React.Component<Props, State> {
  constructor (props: any) {
    super(props)
    // Sets the max length based upon the length param if it exists
    const maxLength = this.props.length
      ? this.props.length
      : this.props.maxLength

    let pattern = '[0-9,]*'
    if (['decimal', 'currency'].includes(this.props.type)) {
      pattern = `${this.props.type === 'currency' ? '[$]?': ''}[0-9.,]*`
    }
    this.state = {
      maxLength: maxLength,
      pattern: pattern,
      label: this.props.label
        ? `${this.props.label} ${this.props.required ? ' (Required)' : ''}`
        : '',
    }
  }

  /**
   * This is called to place a min and max value upon the numeric input field for validation purposes
   * 
   * @param ref - A reference to the `input` or `textarea` element of the text field
   */
  refUpdate(ref: { min: number; max: number }): void{
    if (ref) {
      if (this.props.min) {
        ref.min = this.props.min
      }
      if (this.props.max) {
        ref.max = this.props.max
      }
    }
  }

  numericOnChange(event: { currentTarget: { value: any; id: string }; type: string | undefined }){
    let value = event.currentTarget.value
    let hasMaxLength = false;

    // Strip characters which don't match the expected regex pattern
    if (this.state.pattern && value.match(this.state.pattern)) {
      let val = value.match(this.state.pattern)
      value = val ? val[0] : ''
    }

    // prevent value from exceeding max length
    if(value.length >= this.props.maxLength){
      value = value.slice(0,this.props.maxLength)
      hasMaxLength = true;
    }

    // Format decimal with leading zero if necessary, and 
    if (this.props.type === 'decimal' && value.match(/^[.]\d*/)) {
      value = '0' + value
    }
    // filter out value masking
    value = value.replace(/[$,]/g, '').toUpperCase()
    if(this.props.onUpdate){
      this.props.onUpdate(event.currentTarget.id, value, this.props.errorMessage, event.type, hasMaxLength)
    }
  }

  numericOnBlur(event: { currentTarget: { value: any; id: string }; type: string | undefined }){
    let value = event.currentTarget.value

    // strip out currency value masking
    value = `${value || ''}`
    value = value.replace(/[$,]/g, '')

    if (value.length > 0) {
      if (this.props.type === 'currency') {
        // break currency into the integer and decimal places, if they have a value
        const parts = value.split('.')
        // pad currency value with 0 if no other value
        const integer = parts[0] || '0'
        // set decimal value to 2 decimal places
        let decimal = parts[1] || ''
        while (decimal.length < 2) { decimal += '0' }
        value = `${integer}.${decimal}`
      }
    }

    let errorMessage
    // validate field and ensure a required field isn't empty
    if ((value.length === 0 && this.props.required) || (value.length > 0 && !validateField(this.props, value))) {
      errorMessage = this.props.errorText
    }
    if (this.props.onError && errorMessage) { this.props.onError(errorMessage) }

    if(this.props.onUpdate){
      this.props.onUpdate(event.currentTarget.id, value, errorMessage, event.type)
    }

    if(this.props.onFocusLost){
      this.props.onFocusLost(event.currentTarget.id, value, errorMessage, event.type)
    }
  }

  onKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {
    let errorMessage
    const value: any = event.currentTarget.value
    
    
    if (event.key === 'Enter') {
      this.props.onFocusLost(event.currentTarget.id, value, errorMessage, event.type)
    }
  }

  render(): JSX.Element{
    // Determines the size of the input field based upon the given maxLength
    let calculatedSize
    if (this.props.type === 'currency' || !this.state.maxLength) {
      calculatedSize = 12
    } else if (this.state.maxLength <= 2) {
      calculatedSize = 3
    } else {
      calculatedSize =
        this.state.maxLength % 2 === 0
          ? this.state.maxLength
          : this.state.maxLength + 1
    }
    return (
      <TextField
        value={maskValue(this.props.value, this.props.type)}
        ariaLabel={this.props.ariaLabel}
        style={{
          maxWidth: `${calculatedSize}em`
        }}
        numeric
        pattern={this.state.pattern}
        onChange={(event) => this.numericOnChange(event)}
        onBlur={(event) => this.numericOnBlur(event)}
        onKeyUp={(event) => this.onKeyUp(event)}
        placeholder={this.props.placeholder}
        name={this.props.inputId}
        id={this.props.inputId}
        label={CreateLabel({label: this.state.label})}
        required={this.props.required}
        errorMessage={this.props.errorMessage}
        className={`input-field ${this.props.className || ''}`}
        disabled={this.props.disabled}
        inputRef={(ref) => this.refUpdate(ref)}
      />
    )
  }
}

export default NumericInput
