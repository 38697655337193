import React from 'react';
import './TextBlock.scss'
import {BackToTopButton} from "../BackToTopButton/BackToTopButton";

interface DocumentationEntry {
    heading: string,
    id: string,
    text: string
}

interface Props {
    DocData: DocumentationEntry[]
}

interface State { }

export class TextBlock extends React.Component<Props, State> {

    // @ts-ignore
    contentClickHandler = (e) => {
        const targetLink = e.target.closest('a');
        if(!targetLink) return;
        e.preventDefault();

        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        window.location.assign(targetLink.href)
    };

    render(): JSX.Element {
        const DocPage = this.props.DocData.map((element, index) => (
            <div key={index}>
                <h2 id={element.id} className={"body-heading-hr"} tabIndex={0} >{element.heading}</h2>
                {
                    (index < this.props.DocData.length-1) ? (
                    <div className={"body-section-hr"}>
                    <div className={`${element.id}-text`} dangerouslySetInnerHTML={{ __html: element.text }} tabIndex={0} onClick={this.contentClickHandler} />
                    </div>
                    ) : (
                    <div>
                        {
                            element.text === "null" ?
                                null :
                                (<div className={`${element.id}-text`} dangerouslySetInnerHTML={{ __html: element.text }} tabIndex={0} onClick={this.contentClickHandler} />)
                        }
                    </div>
                    )
                }
            </div>
        ));

        return (
            <div role='article' className={"text-block"}>
                {DocPage}
                <BackToTopButton />
            </div>
        );
    }

}