const FILE_DATA = [
    {
        label: "CSV",
        value: "csv",
        defaultChecked: true,
        disabled: false
    },
    {
        label: "Excel",
        value: "excel",
        disabled: false
    }
]

export default FILE_DATA;