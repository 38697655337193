import React from 'react';
import './HeroBanner.scss'

interface DataUpdatedEntry {
    text: string,
    date: string
}

interface Props {
    inputId: string,
    heading: string,
    text?: string,
    htmlFormattedText?: string,
    variation?: number,
    button1?: React.ReactNode,
    button2?: React.ReactNode
}

interface State {

}

export class HeroBanner extends React.Component<Props, State> {


    render(): JSX.Element {
        if(this.props.variation === 1) {
            return (
                
                <div role="application" className={`${this.props.inputId} hero-banner-var-1 ds-u-color--white ds-u-sans`}>
                    <div className={"text-area-var-1 ds-u-color--white ds-u-float--left"}>
                        <h1 className={"headings-var-1 ds-u-font-size--4xl ds-u-font-weight--bold"} tabIndex={0}>
                            {this.props.heading}
                        </h1>
                        <p className={"hero-banner-text-var-1 ds-u-font-size--lg ds-u-font-weight--normal"}>
                            {
                                !!this.props.htmlFormattedText? (
                                    <div dangerouslySetInnerHTML={{ __html: this.props.htmlFormattedText }} tabIndex={0} />
                                ) : (this.props.text)
                            }
                        </p>
                    </div>
                </div>
                            
            );
        } else if (this.props.variation === 2) {
            return (
                <div role="application" className={`${this.props.inputId} hero-banner-var-2`}>
                    <div className={"text-area-var-2 ds-u-valign--middle ds-u-color--white"}>
                        <h1 className={"heading-var-2 ds-u-font-size--4xl ds-u-font-weight--bold"} tabIndex={0}>{this.props.heading}</h1>
                    </div>
                </div>
            );
        } else if (this.props.variation === 3) {
            return (
                <div role="application" className={`${this.props.inputId} hero-banner-var-3`}>
                    <div className={"text-area-var-3 ds-u-valign--middle ds-u-color--white"}>
                        <h1 className={"heading-var-3 ds-u-font-size--4xl ds-u-font-weight--bold"} tabIndex={0}>{this.props.heading}</h1>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div role="application" className={`${this.props.inputId} hero-banner-var`}>
                    <div className={"ds-l-container ds-base"}>
                    <div className={"text-area ds-u-valign--bottom ds-l-row ds-u-color--white"}>
                        <h1  className={"heading ds-u-valign--middle ds-l-col--8 ds-l-sm-col--9 ds-u-font-size--4xl ds-u-font-weight--bold"} tabIndex={0}>{this.props.heading}</h1>
                        <p className={"hero-banner-text ds-l-col--12 ds-lg--col--9 ds-u-font-size--lg ds-u-sans ds-u-font-weight--normal"} tabIndex={0}>{this.props.text}</p>
                        {this.props.button1} 
                        </div>
                    </div>
                </div>
            );
        }

    }
}