import {Dropdown} from '@cmsgov/design-system'
import React from 'react'
import {InputChoice} from '../../InputField/InputField'
import CreateLabel from '../InputLabel/InputLabel'

interface Props{
  inputId: string,
  label?: string,
  hint?: string,
  ariaLabel?: string,
  required?: boolean,
  disabled?: boolean,
  size?: "small" | "medium",
  defaultValue?: string | number,
  choices?: InputChoice[],
  className?: string,
  value?: string | number,
  errorMessage?: string,
  onError?: (errorMessage:string)=>void,
  onUpdate?: (id:string, value:string|number, error?:string, eventType?:string) =>  void
  onFocusLost: (id:string, value:string|number) =>  void
}

interface State{
  label: string
}

export class DropDown extends React.Component<Props, State> {
  constructor (props: any) {
    super(props)
    this.state = {
      label: this.props.label
        ? `${this.props.label} ${this.props.required ? ' (Required)' : ''}`
        : ''
    }
  }

  /**
   * Sorts the passed in Choice groups in a decending order
   * 
   * @param a - The first of the choice groups to compare
   * @param b - The second of the choice groups to compare
   */
  sortDecending (a:string, b:string): number{
    if (a === 'noGroup') {
      return 1
    }
    if (b === 'noGroup') {
      return -1
    }
    if (a < b) {
      return 1
    }
    if (a > b) {
      return -1
    }
    return 0
  }

  /**
   * Calls updates when a choice has been selected from a choice list
   * 
   * @param component - The component containing the choice list
   * @param event - The event that triggered the update
   */
  choiceListOnChange(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const value = event.currentTarget.value
    if (this.props.onError && this.props.errorMessage) {
      this.props.onError(this.props.errorMessage)
    }
    event.type = 'select'
    if (this.props.onUpdate) {
      this.props.onUpdate(event.currentTarget.id, value, this.props.errorMessage, event.type)
    }
  }

  choiceListOnBlur(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const value = event.currentTarget.value

    if (this.props.onFocusLost) {
      this.props.onFocusLost(event.currentTarget.id, value)
    }
  }

  choiceListOnKeyDown(event: React.KeyboardEvent<HTMLSelectElement>) {
    const value = event.currentTarget.value

    if (event.key === 'Enter' && !!value) {
      event.preventDefault();
      this.props.onFocusLost(event.currentTarget.id, value)
    }
  }

  render(): JSX.Element{
    let noGroup: InputChoice[] = []
    const choiceGroups =
    // If choices has values then parse through them and create an array, otherwise create an empty object
      this.props.choices && this.props.choices.length > 0
        ? this.props.choices.reduce(
          (groupings, choice) => {
            // If the choice does not have a defined group, place it within the noGroup bucket
            if (choice.group === undefined || choice.group === null) {
              groupings.noGroup.push(choice)
            } else {
              // Initialize the array for a group if it doesn't exist
              // @ts-ignore
              if (!groupings[choice.group]) {
                // @ts-ignore
                groupings[choice.group] = []
              }
              // Add the choice to the group it belongs to
              // @ts-ignore
              groupings[choice.group].push(choice)
            }
            return groupings
          },
          // This is the initial value to use within the reduce method
          { noGroup }
        )
        : {}
    // Gathers all of the choices in decending order
    const choices = Object.keys(choiceGroups)
      .sort(this.sortDecending)
      .map((group) => {
        // Creates the choice options for the given group
        // @ts-ignore
        const choices = choiceGroups[group].map((choice: { value: string | number | readonly string[] | undefined; disabled: boolean | undefined; documentation: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; label: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined }) => (
          <option key={`${group}-${choice.value}`} value={choice.value} disabled={choice.disabled}>
            {!!choice.documentation ? choice.documentation : choice.label}
          </option>
        ))
        // If there is no group, then return all choices as is
        if (group === 'noGroup') {
          return choices
        } else {
          // Otherwise, group the choices together under the current group
          return (
            <optgroup label={group} key={group}>
              {choices}
            </optgroup>
          )
        }
      })
    return (
      <Dropdown
        size={this.props.size ? this.props.size : 'small'}
        options={[]}
        value={this.props.value}
        defaultValue={this.props.defaultValue}
        ariaLabel={this.props.ariaLabel}
        onChange={(event) => this.choiceListOnChange(event)}
        onBlur={(event) => this.choiceListOnBlur(event)}
        onKeyDown={(event) => this.choiceListOnKeyDown(event)}
        name={this.props.inputId}
        id={this.props.inputId}
        label={CreateLabel({label: this.props.label || ''})}
        required={this.props.required}
        errorMessage={this.props.errorMessage}
        className={`input-field ${this.props.className || ''}`}
        disabled={this.props.disabled}
      >
        {choices}
      </Dropdown>
    )
  }
}

export default DropDown
