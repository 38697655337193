import { Button } from '@cmsgov/design-system';
import { faAngleDown, faAngleUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { MouseEvent } from 'react';
import Announcements from '../../../components/Announcements/Announcements';
import { ConfirmationModal } from '../../../components/ConfirmationModal/ConfirmationModal';
import InputField from '../../../components/InputField/InputField';
import FILE_DATA from '../../../data/download/FILE_DATA';
import PSF_DATA from '../../../data/download/PSF_DATA';
import { getErrorText, setDownloadLock, modifyFilters, sortItems } from '../../../services/export-data-service';
import './Body.scss';
import { FilterModel } from './FilterModel/FilterModel';
import HelpDrawer from './HelpDrawer/HelpDrawer';
import { ReactComponent as CopyButton } from '../../../images/copy-button.svg';
import { Link } from 'react-router-dom';
import { link } from 'fs';


interface InputChoice {
    label: string,
    value: string,
    defaultChecked?: boolean,
    disabled?: boolean
}

interface FilterState {
    fromDate: string,
    toDate: string,
    providerList: [],
    npiList: [],
    facilityType: string,
    stateAbbr: string,
    facilityTypes: InputChoice[],
    states: InputChoice[],
    stateList: [],
}

interface FileTypeState {
    text: string
}

interface Props {
}

interface State {
    baseUrl: string,
    linkUrl: string,
    filters: [],
    providerList: [],
    npiList: [],
    stateList: [],
    downloadModalIsOpen: boolean,
    showHelp: boolean,
    error: string,
    psfType: string,
    filterByLatestRecord: boolean,
    psfTypeText: { [key: string]: string },
    radioButtonsPSF: InputChoice[],
    fileType: string,
    fileTypeText: { [key: string]: FileTypeState },
    radioButtonsFile: InputChoice[],
    fromDate: string,
    toDate: string,
    announcement: string,
    downloadLock: boolean,
    facilityType: string,
}

export class Body extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        let baseUrl = `${window.location.origin}/fiss/v2/`;

        this.state = {
            baseUrl: baseUrl,
            linkUrl: (`${baseUrl}inpatient/export?format=csv`),
            filters: [],
            providerList: [],
            npiList: [],
            stateList: [],
            filterByLatestRecord: false,
            downloadModalIsOpen: false,
            showHelp: false,
            error: '',
            psfType: "inpatient",
            psfTypeText: {
                inpatient: "The Inpatient PSF includes data for all 7 Part A institutions and facilities: home health " +
                    "agencies (HHA), hospice, acute care inpatient (IPPS), inpatient psychiatric facilities (IPF)," +
                    " inpatient rehabilitation facilities (IRF), long-term care hospitals (LTCH), and skilled nursing " +
                    "facilities (SNF)",
                outpatient: ""
            },
            radioButtonsPSF: PSF_DATA,
            fileType: "csv",
            fileTypeText: {
                csv: {
                    text: "With CSV files, Excel drops leading zeroes by default."
                },
                excel: {
                    text: "Excel files require a date range of 3 years or less."
                }
            },
            radioButtonsFile: FILE_DATA,
            fromDate: "",
            toDate: "",
            announcement: "",
            downloadLock: false,
            facilityType: ""
        }
    }

    /**
     * Updates PSF type specified by the radio buttons
     */
    async onChangePSF(psf: string): Promise<void> {
        let newState = JSON.parse(JSON.stringify(this.state))
        newState.psfType = psf;
        if (!!newState.filters.filter((filter: { param: string | string[]; }) => filter.param.includes("facilityType")).length) {
            // @ts-ignore
            let index = newState.filters.findIndex((filter: { param: string | string[]; }) => filter.param.includes("facilityType"));

            newState.filters.splice(index, 1);
        }

        await this.setState(newState);

        this.validate(this.state.filters);
        this.updateUrl(this.state.filters);

        if (this.state.psfType === "outpatient" && this.state.facilityType !== "") {
            this.updateFields("facilityType", "");
        }
    }

    /**
     * Updates File format wanting to download the content as specified by the radio buttons
     */
    async onChangeFile(file: string): Promise<void> {
        let newState = JSON.parse(JSON.stringify(this.state))
        newState.fileType = file;
        await this.setState(newState);

        this.validate(this.state.filters, file);
        this.updateUrl(this.state.filters);
    }

    async onChangeFilter(filterByLatestRecord: boolean): Promise<void> {
        let newState = JSON.parse(JSON.stringify(this.state))
        newState.filterByLatestRecord = filterByLatestRecord;
        
        if(filterByLatestRecord){
            const newFilters = newState.filters?.filter((filter: { param: string; }) =>
            !(filter.param.includes("fromDate") ||filter.param.includes("toDate")) 
            );
            newState.filters = newFilters;
        }
        await this.setState(newState);
        this.updateUrl(this.state.filters);
    }

    /**
     * These are for managing when the Confirmation Modal is shown.
     * This action is called when they haven't specified any filters and click the "Download Bulk File" button
     */
    showDownloadModal(show: boolean): void {
        this.setState({ downloadModalIsOpen: show });
    }

    async exportOnConfirm(event: any, filterState: FilterState): Promise<void> {
        if (event.key === " " || event.key === "Enter" || event.key === "Spacebar" || event.type === "click" || event.type === "mousedown") { // "Spacebar" for IE11 support
            await this.addFilters(filterState);
            this.setState({ announcement: "Selected Filters were applied." })
        }
    }

    copyToClipboard(event: any, text: any) {
        navigator.clipboard?.writeText(text)

        this.setState({ announcement: `${text} copied to clipboard` })

    }

    /**
     * This action is called when the user submits the filters from the Filter Modal
     */
    async addFilters(filterState: FilterState): Promise<void> {
        let { filters } = this.state;
        let {
            fromDate,
            toDate
        } = filterState;

        let newState = JSON.parse(JSON.stringify(this.state));

        if (!!fromDate) newState.fromDate = fromDate;
        if (!!toDate) newState.toDate = toDate;

        await modifyFilters(filters, filterState);
        sortItems(filters);
        newState.filters = filters;
        await this.setState(newState);

        /**
         * these methods are called to validate the filters added, update the URL under the "Get Link" section and to hide the Filter Modal
         */
        this.validate(filters);
        this.updateUrl(filters);
        document.getElementById("add-filters")?.focus();
    }

    /**
     * this action is called when the user clicks the "X" button on the filter on the list of filters.
     */
    async removeFilter(filterIndex: number, event: MouseEvent, removedParam: string): Promise<void> {
        event.preventDefault();

        let newState = JSON.parse(JSON.stringify(this.state))
        newState.filters.splice(filterIndex, 1);
        await this.setState(newState);

        /**
         * Once the filter is removed they need to be re-validated then update the URL under the "Get Link" section
         */
        this.specificParamUpdates(removedParam);
        this.validate(newState);
        this.updateUrl(newState.filters);
    }

    async clearFilters(): Promise<void> {
        let newState = JSON.parse(JSON.stringify(this.state))
        newState.filters = [];
        newState.npiList = [];
        newState.stateList = [];
        newState.providerList = [];
        newState.fromDate = '';
        newState.toDate = '';
        newState.facilityType = '';
        await this.setState(newState);

        this.validate(this.state.filters);
        this.updateUrl(this.state.filters);
    }

    async updateUrl(filters: []): Promise<void> {
        /**
         * if there are no filters then just return the base URL path.
         * Otherwise add query parameters specified under the Filters -> param field
         */
        if (!filters.length) {
            let linkURL = this.state.baseUrl.concat(this.state.psfType).concat("/export").concat("?")
                .concat("format=").concat(this.state.fileType);

            if (this.state.filterByLatestRecord) {
                linkURL = linkURL.concat("&").concat("latestRecordOnly=").concat(`${this.state.filterByLatestRecord ? "Y" : ""}`);
            }
            this.setState({
                linkUrl: linkURL
            })
        } else {
            let newUrl = this.state.baseUrl.concat(this.state.psfType).concat("/export").concat("?")
                .concat("format=").concat(this.state.fileType).concat("&");
            
            if (this.state.filterByLatestRecord) {
                newUrl = newUrl.concat("latestRecordOnly=").concat(`${this.state.filterByLatestRecord ? "Y" : ""}`).concat("&");
            }

            let i;
            for (i = 0; i < filters.length; i++) {
                if (i > 0) newUrl = newUrl.concat("&");
                // @ts-ignore
                newUrl = newUrl.concat(filters[i].param);
            }

            let newState = JSON.parse(JSON.stringify(this.state))
            newState.linkUrl = newUrl;
            await this.setState(newState);
        }
    }

    async validate(filters: [], fileType?: string): Promise<void> {
        let { fromDate, toDate, filterByLatestRecord } = this.state;

        /**
         * if no fileType passed into the validate method then default it to whatever fileType is saved to the State
         * This is for when the fileType is updated and hasn't yet updated the State but needs to be validated.
         */
        if (!fileType) {
            fileType = this.state.fileType;
        }

        let errorText = await getErrorText(filters, fileType, fromDate, toDate, filterByLatestRecord);
        let downloadLock = await setDownloadLock(filters, fileType, filterByLatestRecord);
        let newState = JSON.parse(JSON.stringify(this.state));
        newState.error = errorText;
        newState.downloadLock = downloadLock;
        await this.setState(newState);
    }

    async specificParamUpdates(param: string): Promise<void> {
        let newState = JSON.parse(JSON.stringify(this.state));
        let filterType = param.slice(0, param.indexOf("="));
        let filter = param.slice(param.indexOf("=") + 1);
        let index = 0;

        switch (filterType) {
            case 'oscarNumber':
                index = newState.providerList.findIndex((provider: string) => provider === filter);
                newState.providerList.splice(index, 1);
                break;

            case 'npi':
                index = newState.npiList.findIndex((npi: string) => npi === filter);
                newState.npiList.splice(index, 1);
                break;

            case 'state':
                index = newState.stateList.findIndex((state: string) => state === filter);
                newState.stateList.splice(index, 1);
                break;

            case 'fromDate':
                newState.fromDate = ""
                break;

            case 'toDate':
                newState.toDate = ""
                break;

            case 'facilityType':
                newState.facilityType = ""
                break;
        }

        await this.setState(newState);
    }

    async updateFields(fieldname: string, value: string): Promise<void> {
        switch (fieldname) {
            case 'fromDate':
                this.setState({ fromDate: value });
                break;
            case 'toDate':
                this.setState({ toDate: value });
                break;
            case 'facilityType':
                this.setState({ facilityType: value });
                break;
        }
    }

    /**
     * this action is called when the user is ready to download the file
     */
    async submit(event: MouseEvent, url: string): Promise<void> {
        event.preventDefault();
        window.location.href = url;
        this.setState({ downloadModalIsOpen: false });
    }

    render(): JSX.Element {
        const {
            filters,
            psfTypeText,
            radioButtonsPSF,
            fileTypeText,
            radioButtonsFile
        } = this.state;
        return (
            <div role="main" id="main-content" className="download-body ds-base ds-u-padding--0">

                <h2 role="heading" className="ds-h3 ds-u-margin--0" tabIndex={0}>
                    Select Inpatient or Outpatient Provider Specific File (PSF)
                </h2>
                <div role= "article" className="psf-type-text">
                    <p className="ds-u-margin-top--3 ds-l-col--12 ds-l-sm-col--11" tabIndex={this.state.psfType === 'outpatient' ? -1 : 0}
                        style={{ paddingLeft: 0 }}>
                        {psfTypeText[this.state.psfType]}
                    </p>
                </div>

                <div>
                    <div className={`ds-c-button ${this.state.psfType == "inpatient" ? "ds-c-button--primary" : ""}`}
                        onClick={() => this.onChangePSF("inpatient")} tabIndex={0}
                        style={{ margin: "0 4% 0 0", width: "11%", minWidth:"90px" }}
                        role="button">
                        Inpatient
                    </div>
                    <div className={`ds-c-button ${this.state.psfType == "outpatient" ? "ds-c-button--primary" : ""}`}
                        style={{ margin: "0 4% 0 0", width: "11%" , minWidth:"90px"}} tabIndex={0}
                        onClick={() => this.onChangePSF("outpatient")}
                        role="button">
                        Outpatient
                    </div>
                </div>
                <h3 role="heading" className="ds-h3" style={{ marginTop: "30px" }} tabIndex={0}>
                    Select File Format
                </h3>
                <div style={{ marginTop: "2%" }}>
                    <div className={`ds-c-button ${this.state.fileType == "csv" ? "ds-c-button--primary" : ""}`}
                        onClick={() => this.onChangeFile("csv")} tabIndex={0}
                        style={{ margin: "0 4% 0 0", width: "11%" }}
                        role="button">
                        CSV
                    </div>
                    <div className={`ds-c-button ${this.state.fileType == "excel" ? "ds-c-button--primary" : ""}`}
                        style={{ margin: "0 4% 0 0", width: "11%" }} tabIndex={0}
                        onClick={() => this.onChangeFile("excel")}
                        role="button">
                        Excel
                    </div>
                </div>

                <div role="complementary" className="ds-u-margin-top--2 ds-u-margin-bottom--4" tabIndex={0}>
                    {
                        this.state.fileType === "csv" ? (
                            <div className="">
                                <p className=""
                                    aria-label={fileTypeText[this.state.fileType].text + ". Learn how to fix this."}>
                                    <strong>Note: </strong>{fileTypeText[this.state.fileType].text} <HelpDrawer />
                                </p>
                            </div>
                        ) : (
                            <div className="">
                                <p className="" aria-label={fileTypeText[this.state.fileType].text}>
                                    <strong>Note: </strong>{fileTypeText[this.state.fileType].text}
                                </p>
                            </div>
                        )
                    }
                </div>

                <div className="filters">
                    <h3 role="heading" className="ds-h3 ds-u-margin-bottom--1" tabIndex={0}>Filters</h3>

                    <InputField
                        type="individualRadio"
                        className="ds-u-margin--0 radio-button filter-by"
                        inputId="filterByLatestRecord"
                        value={this.state.filterByLatestRecord}
                        label={"Filter By: Latest Record Only"}
                        size="medium"
                        hint={"The Latest Record Only filter will return the most recent effective date record for each provider."}
                        onUpdate={(id: string, value: any) => this.onChangeFilter(value)}
                    />

                    {
                        (
                            <FilterModel
                                onConfirm={(event: any, state: any) => this.exportOnConfirm(event, state)}
                                variation="success"
                                filters={this.state.filters}
                                providerList={this.state.providerList}
                                npiList={this.state.npiList}
                                stateList={this.state.stateList}
                                fileType={this.state.fileType}
                                psfType={this.state.psfType}
                                size="narrow"
                                fromDate={this.state.fromDate}
                                toDate={this.state.toDate}
                                facilityType={this.state.facilityType}
                                filterByLatestRecord={this.state.filterByLatestRecord}
                                updateFields={(fieldName: string, value: string) => this.updateFields(fieldName, value)}
                                clearFilters={() => this.clearFilters()}
                            />
                        )
                    }

                    <div className="filter-options" >
                        {filters.length > 0 && (
                        <div className="filter-header" tabIndex={0}>Filters Applied:</div>
                        )}
                        {
                            filters.length > 0 && filters.map((filter, index) => (
                                <div className="applied-filter ds-u-margin-right--2 ds-u-margin-top--2" key={index}>
                                    <a href="/"
                                        className="close-button ds-u-float--right ds-u-margin-right--1 ds-u-color--primary-darker"
                                        role="button"   
                                        onClick={(event: React.MouseEvent<HTMLElement>) =>  // @ts-ignore
                                            this.removeFilter(index, event, filter.param)}
                                        aria-label={`Remove ${        // @ts-ignore
                                            filter.label} Button`}>
                                        <FontAwesomeIcon icon={faTimes} size="lg" /> </a>{        // @ts-ignore
                                        filter.label}</div>
                            ))
                        }

                    </div>
                </div>

                <div className="submit-buttons">
                    {
                        filters.length
                            ? <Button className="download-button ds-c-button ds-c-button--primary"
                                variation="primary"
                                onClick={(event) => this.submit(event, this.state.linkUrl)}
                                size={"big"}
                                disabled={!!this.state.error || this.state.downloadLock}>Download</Button>
                            : <Button className="download-button ds-c-button ds-c-button--primary"
                                variation="primary"
                                disabled={!!this.state.error || this.state.downloadLock}
                                size={"big"}
                                onClick={() => this.showDownloadModal(true)}>Download</Button>
                    }

                </div>

                <div role="form" className="download-link">
                    <h3 className="ds-h3" tabIndex={0}>Get Link</h3>
                    <InputField
                        type="text"
                        label={this.state.linkUrl}
                        ariaLabel="getLink"
                        title={this.state.linkUrl}
                        disabled={true}
                        inputId="download-link-url"
                        value={!!this.state.error || this.state.downloadLock ? "Not Available" : this.state.linkUrl}
                        size="medium"
                    />
                    <Button onClick={(event) => this.copyToClipboard(event, this.state.linkUrl)}
                        disabled={!!this.state.error}
                        role="button"
                        aria-label={`Copy URL ${this.state.linkUrl} to clipboard`}>
                        <CopyButton /></Button>
                </div>
                <Announcements
                    message={this.state.announcement}
                />

                {this.state.downloadModalIsOpen &&
                    (
                        <ConfirmationModal
                            heading="Are you sure?"
                            onConfirm={(event: React.MouseEvent<Element, globalThis.MouseEvent>) => this.submit(event, this.state.linkUrl)}
                            onCancel={() => this.showDownloadModal(false)}
                            variation="primary"
                            size="narrow"
                            text={"Would you like to proceed?"}
                            warningMsg={"Warning: You are about to download a large, bulk file."}
                            className={"ds-u-text-align--center"}
                        />
                    )
                }
            </div>
        );
    }
}