import React from 'react';

import { Header } from "../../components/Header/Header";
import { Button, UsaBanner } from "@cmsgov/design-system";
import Footer from "../../components/Footer/Footer";
import { Banner } from "../../components/Banner/Banner";
import { HeroBanner } from "../../components/HeroBanner/HeroBanner";
import { Body } from "./Body/Body";
import RightArrow from "../../images/Path.svg";
import './Home.scss'
import { utagView } from "../../services/analytics-service"
import SkipLink from '../../components/SkipLink/SkipLink';

interface Props {
}

interface State { }

export class Home extends React.Component<Props, State> {

    onClick(event: any) {
        event.preventDefault();
        window.location.href = '#/Download';
    }

    async componentDidMount(): Promise<void> {
        var pathName = "/#/"
        utagView(pathName); 
        try{
        var bannerElem=document.querySelector<HTMLElement>('.ds-c-usa-banner__button')!;
        bannerElem.setAttribute('tabindex', '0')
        } catch(e){
           
        }
    }

    render(): JSX.Element {
        const bannerText = window.location.hostname.includes("pds.mps.") ? "" : "<b>beta site</b> for";
        return (
            <div className={"home-page"}>
                <SkipLink className="primary ds-c-button ds-c-button--primary ds-c-button--big download-button ds-c-button ds-c-button--primary skip-link" skipTo='#main-content'>
                    <a role='button' href="#">Skip to main content</a>
                </SkipLink>
                <UsaBanner/>
                <Header />
                    <Banner
                        inputId={"home-welcome-banner"}
                        text={"<p>\n" +
                            "                                Welcome to the "+
                            bannerText +
                            "  Provider Data Service. <br />\n" +
                            "                                We are continuing to add data, functionality, and tools and would love to hear from you!  Submit your feedback by emailing <a href=\"mailto:ModernizingMedicare@cms.hhs.gov?subject=Provider%20Specific%20File%20Feedback\">ModernizingMedicare@cms.hhs.gov</a>.\n" +
                            "                            </p>"} />
                
                <HeroBanner
                    inputId="hero-banner"
                    heading="Provider Data Service"
                    text="The Provider Data Service enables public access to the Provider Specific File, which drives payment calculations for the 16,000 Medicare-participating hospitals and institutions nationwide."
                    button1={
                        <Button
                            id={"home-download-button"}
                            onClick={(event: any) => this.onClick(event)}
                            className={"home-download-button ds-u-font-size--xl"}
                        >Download the Data <img className={"right-download-arrow ds-u-padding-left--1"} src={RightArrow} alt={"Right Download Arrow"} />
                        </Button>} />
                <Body />
                <Footer />
            </div>

        );
    }

}