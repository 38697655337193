import React from 'react';

import {Header} from "../../components/Header/Header";
import {UsaBanner} from "@cmsgov/design-system";
import Footer from "../../components/Footer/Footer";
import {Body} from "./Body/Body";
import './Download.scss'
import {HeroBanner} from "../../components/HeroBanner/HeroBanner";

import {BackToTopButton} from "../../components/BackToTopButton/BackToTopButton";
import {utagView} from "../../services/analytics-service"
import SkipLink from '../../components/SkipLink/SkipLink';

interface Props {
}

interface State { }

export class Download extends React.Component<Props, State> {

    async componentDidMount(): Promise<void> {
	    var pathName = "/#/Download"
        utagView(pathName)
        try{
        var bannerElem=document.querySelector<HTMLElement>('.ds-c-usa-banner__button')!;
        bannerElem.setAttribute('tabindex', '0')
        } catch(e){
           
        }
    }
    
    render(): JSX.Element {
        return (
            <div className={"download-page"}>
                <SkipLink className="primary ds-c-button ds-c-button--primary ds-c-button--big download-button ds-c-button ds-c-button--primary skip-link" skipTo='#main-content'>
                    <a role='button' href="#">Skip to main content</a>
                </SkipLink>
                <UsaBanner />
                <Header />
                <HeroBanner
                    inputId={"download-hero-banner"}
                    variation={1}
                    heading={"Download the Data"}
                    htmlFormattedText={"Get current and historical provider-specific data for all 7 Part A inpatient\n" +
                    "                            institutions as well as the outpatient file."}
                />
                <Body />
                <BackToTopButton />
                <Footer/>
            </div>

        );
    }

}