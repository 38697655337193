import React from 'react';
import './Header.scss';
import { Link } from "react-router-dom";

interface Props {
    type?:string
}

interface State {
}

export class Header extends React.Component<Props, State> {

    render(): JSX.Element {
        const isNavigated = window?.location?.hash !== '#/';
        return (
            <header  role="banner" key ={this.props.type} className="header ds-u-font-style--normal ds-u-font-weight--bold">
                <div  className="ds-u-display--flex" style={{marginTop:"1%"}}>
                    <Link to={"/"} className={"header-link-container"}>Provider Data Service</Link>
                </div>
                <nav
                    className={"header-nav-links ds-u-font-size--base ds-u-text-align--center ds-u-margin-left--auto ds-u-margin-right--6"}
                >
                    <Link to={"/Data_dictionary"} className={"navlink ds-u-padding--4"}>Data Dictionary</Link>
                    <Link to={"/Developer_documentation"} className={"navlink ds-u-padding--4"}>Developer Documentation</Link>
                    <Link to={"/Change_log"} className={"navlink ds-u-padding--4"}>Change Log</Link>
                    <Link to={"/Support"} className={"navlink ds-u-padding--4 ds-u-margin-right--3"}>Support</Link>
                    <Link to={"/Download"} className={"header-nav-button ds-c-button ds-u-margin-top--4"}>Download
                        Data</Link>
                </nav>

            </header>
        );
    }

}