import React from 'react'

interface LabelProps{
  label: string,
  inputId?: string,
  disabled?: boolean,
  hint?: string,
  onHelp?: Function
}

/**
 * Creates the label for any given input field based upon the label value provided to the function.
 * This also accepts other values such as disabled, onHelp, and hint
 * 
 * @param label - The title to apply to the created label
 * @param props - A properties object to contain the extra properties that might be desired for the label
 */
const CreateLabel = (props:LabelProps): JSX.Element|null => {
  let hintText = null;
  const { label } = props;

  if (!!props.hint) {
  hintText = React.createElement(
    'p',
    { className: 'ds-u-margin--0 ds-c-field__hint' },
    props.hint
  )
  }
  return label ? React.createElement(
    'div',
    { className: 'ds-u-margin--0' },
    label.trim(),
    hintText
  ) : null
}

export default CreateLabel;
