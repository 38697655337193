import React from 'react';
import './Body.scss'
import {SideNav} from "../../../components/SideNav/SideNav";
import HomeSideNav from "../../../data/home/HomeSideNav";
import HomeDocData from "../../../data/home/HomeDocData";
import {TextBlock} from "../../../components/TextBlock/TextBlock";

interface Props {

}

interface State {
}

export class Body extends React.Component<Props, State> {

    render(): JSX.Element {
        return (
            <div role="main" className="home-body">
                <section className="ds-l-container ds-base">
                    <div className="ds-l-row">
                        <aside className="ds-l-col--4 ds-u-margin-left--right ds-u-display--none ds-u-sm-display--block">
                            <SideNav
                                sideNav={HomeSideNav} />
                        </aside>
                        <article className="ds-l-col--12 ds-l-sm-col--7">
                            <TextBlock
                                DocData={HomeDocData} />
                        </article>
                    </div>
                </section>
            </div>
        );
    }
}