import './Body.scss'
import React, {useState} from 'react';
import aarowDown from '../../../images/Arrow-down.svg'
import aarowUp from '../../../images/Arrow-up.svg'
import {Button} from "@cmsgov/design-system";
import SupportFAQ from '../../../data/Support/SupportFAQ'


function SupportDropDown() {
    let [selected, setSelected] = useState(undefined)

    const toggle = (event: any, i: any) => {
        if (event.key === " " || event.key === "Enter" || event.key === "Spacebar" || event.type === "click" || event.type === "mousedown") { // "Spacebar" for IE11 support
            // Prevent the default action to stop scrolling when space is pressed
            event.preventDefault();

            if (selected === i) {
                return setSelected(undefined)
            }

            setSelected(i)
        }
    }

    return (
        <div className="accordion">

            {SupportFAQ.map((items, i) => (
                <div className="accordion-item">
                    <div  className="accordion-item-header">
                        <h4  tabIndex={0}>{items.heading}</h4>
                        <Button
                            variation={"transparent"}
                            onClick={(event) => toggle(event, i)}
                            onKeyDown={(event) => toggle(event, i)}
                            tabIndex={0}
                            className={`FAQ-${i}-button`}
                            aria-expanded={selected === i}
                            aria-label={`${items.ariaLabel} press enter to ${selected === i ? "collapse" : "expand"}`}
                            >
                            <img src={selected === i ? aarowUp : aarowDown}
                                 alt={""}
                            />
                        </Button>


                    </div>
                    <div
                        className={selected === i ? 'accordion-item-body-content' : 'accordion-item-body'} tabIndex={0}>
                        {selected === i ? items.answer : null}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default SupportDropDown