/**
 * Will prepend the desired number of zeroes to the given value to ensure it hits the min length
 * 
 * @param value - The value to pad with zeroes
 * @param minLength - The min length the resulting value needs to be
 */
function padValue(value:string, minLength:number): string{
  if (value.length > 0 && minLength) {
    while (value.length < minLength) {
      value = `0${value}`
    }
  }
  return value
}

/**
 * Will ensure that the given value is correctly displayed as a currency value
 * 
 * @param value - The value to have the mask applied to
 */
function currencyMask(value:string): string{
  // remove value masking and non-numerical values
  let newValue = value.replace(/[^\d.]/g, '')
  if (newValue.length) {
    // determine if currency field a decimal point entered
    const decimal = newValue.match(/.*\..*/) ? '.' : ''
    // split currency into dollars and cents
    const decimalParts = newValue.split('.')
    // parse integer value or defaut to 0
    const integerValue = parseInt(decimalParts[0] || '0').toLocaleString('en-US')
    // parse decimal value if it exists
    const decimalValue = decimalParts[1] === undefined ? '' : decimalParts[1].slice(0, 2)
    newValue = integerValue + decimal + decimalValue
  }
  return '$' + newValue
}

/**
 * Will mask the given value as a date string
 * 
 * @param value - The date string to mask
 */
function dateMask(value:string): string{
  // for a fully written date (length of 10) do not remove parantheses or digits
  // partial date fields will remove any non-digits, including parantheses
  const newValue = value.length < 10 ? value.replace(/[^0-9/]/g, '') : value.replace(/[^0-9]/g, '').slice(0, 8)

  // capture regex groups to determine which parts of the date are partially completed or completed
  const parts = newValue.match(/^(\d{2}\/?|\d\/)(\d{2}\/?|\d\/)?(\d*)/)
  if (parts) {
    // break out date into its parts if they exist
    const month = parts[1] ? parts[1].replace('/', '') : ''
    const day = parts[2] ? parts[2].replace('/', '') : ''
    const year = parts[3] ? parts[3] : ''
    
    // recombine date parts and prevent double parantheses
   // return `${month}/${day}/${year}`.replace('//', '/') (For future reference)
  }
  return value
}


/**
 * Will apply masking to the value passed in
 * 
 * @param value - The value to be masked
 * @param type - The type of masking to be applied
 */
function maskValue(value?:string | number | undefined, type?:string) {
  if (value === undefined || value === null) {
    value = ''
  }
  if (type === 'currency') {
    return currencyMask(value.toString())
  } else if (type === 'date') {
    return dateMask(value.toString())
  }
  return value
}

/**
 * Returns true if the passed in field is valid
 * 
 * @param field - The form field being validated
 * @param value - The value of the field
 */
function validateField(field:any, value:string) {
  if (field.minLength !== undefined && value.length < field.minLength) return false
  if (field.maxLength !== undefined && value.length > field.maxLength) return false
  if (field.min !== undefined && Number(value) < field.min) return false
  if (field.max !== undefined && Number(value) > field.max) return false
  if (field.pattern !== undefined && !value.match(field.pattern)) return false
  return true
}

export { maskValue, validateField, padValue }
