const HomeSideNav = [
    {
        link: "#overview",
        text: "Overview"
    },
    {
        link: "#getting-started",
        text: "Getting Started"
    },
    {
        link: "#about",
        text: "About PDS"
    }
]

export default HomeSideNav;