const PSF_DATA = [
    {
        label: "Inpatient",
        value: "inpatient",
        defaultChecked: true
    },
    {
        label: "Outpatient",
        value: "outpatient",
    }
]

export default PSF_DATA;