import { Dialog} from '@cmsgov/design-system';
import React from 'react';
import FocusLock from '../FocusLock/FocusLock';
import './AlertBox.scss';

interface Props{
  size?: "narrow" | "wide" | "full"
}

interface State{
    showHelp: boolean,
    isLinkExpired:boolean,
}

class AlertBox extends React.Component<Props,State> {
  constructor (props: Props | Readonly<Props>) {
    super(props)

   
    const todaysDate = new Date();
    const expiryDate = new Date(2023, 2, 1);
    let isLinkExpired=false;
    if(todaysDate >= expiryDate)
      isLinkExpired = true;

    this.state = {
      showHelp: true,
      isLinkExpired:isLinkExpired
    }
  }
  componentDidMount(): void {
    if(this.state.isLinkExpired){
      const button =  document.querySelector("div.alert-box button.ds-c-dialog__close");
      button?.remove();
     }
  }
  componentDidUpdate() {
    if (this.state.showHelp) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible"; // or restore the original value
    }
   
  }

 

  handleDrawerClose() {
    this.setState({ showHelp: false });
  }

  render(): JSX.Element{
    const heading = (
        <div className={"header-text"}>
          <svg
          aria-hidden="true"
          className={"ds-c-icon ds-c-icon--warning"}
          focusable="false"
          id="icon-31"
          viewBox="27 0 160 135"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M179.695 125.388L117.126 10.673a10.39 10.39 0 00-3.832-3.992 10.1 10.1 0 00-5.295-1.467c-1.901 0-3.667.49-5.296 1.467s-2.906 2.308-3.829 3.992L36.303 125.388c-1.901 3.423-1.847 6.845.163 10.267a10.24 10.24 0 003.789 3.746 10.188 10.188 0 005.174 1.387H170.57c1.849 0 3.572-.463 5.175-1.387a10.24 10.24 0 003.789-3.746c2.01-3.423 2.064-6.844.161-10.267zm-61.265-8.148c0 .76-.259 1.398-.773 1.914-.516.516-1.127.773-1.834.773H100.18c-.706 0-1.317-.257-1.833-.773-.516-.517-.774-1.154-.774-1.914v-15.48c0-.76.258-1.397.774-1.914.516-.516 1.126-.773 1.833-.773h15.642c.707 0 1.318.257 1.834.773.515.517.773 1.154.773 1.914v15.48zm-.162-30.47c-.056.543-.341.991-.856 1.344-.517.354-1.154.529-1.915.529h-15.073c-.76 0-1.412-.176-1.955-.529-.544-.354-.815-.801-.815-1.346l-1.385-37.231c0-.761.272-1.331.815-1.711.706-.597 1.358-.896 1.956-.896h17.924c.598 0 1.25.298 1.956.896.543.38.813.896.813 1.548l-1.465 37.396z"
          ></path>
        </svg>
        <p className={"ds-u-font-weight--bold"} tabIndex={0}>Important Notice</p>
        </div>);

    const closeButtonText = (
        <div className={`close-text ds-u-padding-right--2 ${this.state.isLinkExpired ? "hide": ""}`}>
          X
        </div>
    )
    
      return (
        <div className={`ds-base`}>

        {this.state.showHelp && (
          <FocusLock isLocked={this.state.showHelp}>
            <Dialog
                getApplicationNode={() => document.getElementById('root')}
                size={"narrow"}
                onExit={() => this.handleDrawerClose()}
                closeButtonSize={"small"}
                closeButtonText={closeButtonText}
                heading={heading}
                headerClassName={"alert-box-header ds-u-color--white "}
                className={"alert-box alert-box-size"}
            >
              <div className='text-center'>
                <p className={"ds-u-fill--white ds-u-font-weight--bold"}>
                  Welcome to the Provider Data Service.
                </p>
                <p style={{fontSize:"14px"}} className={`ds-u-fill--white ${this.state.isLinkExpired ? "hide":"show"}`}>This URL will expire on March 1, 2023. Please save and click the link below to access this site.</p>
                <p style={{fontSize:"14px"}} className={`ds-u-fill--white ${this.state.isLinkExpired ? "show":"hide"}`}>This URL has expired as of March 1, 2023. Please save and click the link for access.</p>
                <a href="https://pds.mps.cms.gov/#/" target={"_blank"}>pds.mps.cms.gov</a>
              </div>
            </Dialog>
          </FocusLock>
        )}
    </div>
      )
    }
  }

export default AlertBox;
