import React from 'react';
import './SideNav.scss'

import {HashLink} from "react-router-hash-link";

interface SideNavEntry {
    link: string,
    text: string
}

interface Props {
    sideNav: SideNavEntry[];
}

interface State {
}

export class SideNav extends React.Component<Props, State> {


    render(): JSX.Element {
        const SIDE_NAV = this.props.sideNav.map((element, index) => (
            <li key={index}>
                <HashLink to={element.link}>{element.text}</HashLink>
            </li>
        ));

        return (
            <nav role="link" className="side-nav">
                <ul id = "main-content" className="side-nav-items ds-c-list ds-c-list--bare">
                    {SIDE_NAV}
                </ul>
            </nav>
        );
    }
}