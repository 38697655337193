const HomeDocData = [
    {
        heading: "Overview",
        id: "overview",
        text: "<p>\n" +
            "                                    The Provider Specific File (PSF) contains a set of provider attributes and policy rates set\n" +
            "                                    annually by the Centers for Medicare and Medicaid Services. Together, these factors adjust\n" +
            "                                    the base rate for how Medicare will pay providers for each claim under the Prospective\n" +
            "                                    Payment System. For example, the file contains information on a provider’s location for\n" +
            "                                    wage index adjustments, CMS value-based payment incentives, the ratio of Medicaid patients\n" +
            "                                    served, and the ratio of interns and residents at teaching hospitals. The Medicare\n" +
            "                                    Administrative Contractors (MACs) maintain the information on each provider’s PSF record.\n" +
            "                                </p>\n" +
            "                                <p>\n" +
            "                                    To learn more about how these PSF factors drive pricing for payment,\n" +
            "                                    please see the <a href=\"https://webpricer.cms.gov/#/\">CMS Web Pricer estimator tools</a>.\n" +
            "                                </p>"
    },
    {
        heading: "Getting Started",
        id: "getting-started",
        text: "<p>\n" +
            "                                    Get started with accessing and understanding the data with the following steps:\n" +
            "                                </p>\n" +
            "                                <p>\n" +
            "                                    <ul> \n" +
            "                                        <li>\n" +
            "                                            <a href=\"#/Download\">Download and filter</a> historical PSF data with our new export tool\n" +
            "                                        </li>\n" +
            "                                        <li>\n" +
            "                                            <a href=\"#/Data_dictionary\">Review</a> the data dictionary with PSF terms and definitions\n" +
            "                                        </li>\n" +
            "                                        <li>\n" +
            "                                            <a href=\"#/Support\">Join the Community</a> to be notified about updates to the application (coming soon)\n" +
            "                                        </li>\n" +
            "                                        <li>\n" +
            "                                            <a href=\"#/Support\">Ask</a> a question\n" +
            "                                        </li>\n" +
            "                                    </ul>\n" +
            "                                </p>"
    },
    {
        heading: "About PDS",
        id: "about",
        text: "<p>\n" +
            "                                    The Provider Data Service is a CMS initiative to modernize access to provider data across\n" +
            "                                    the Medicare payment system, starting with the Provider Specific File. Once fully\n" +
            "                                    implemented, the service will allow Medicare providers, commercial payers, auditors, and\n" +
            "                                    other public analysts to access PSF data for all Part A hospitals and facilities in near\n" +
            "                                    real-time.\n" +
            "                                </p>\n" +
            "                                <p>\n" +
            "                                    The service is migrating the data from a mainframe environment to the cloud to improve file\n" +
            "                                    accessibility and updating flexibility as well as create an overall enhanced public user\n" +
            "                                    experience. Please email us at <a href=\"mailto:ModernizingMedicare@cms.hhs.gov?subject=Provider%20Specific%20File%20Feedback\">ModernizingMedicare@cms.hhs.gov</a> with your comments or\n" +
            "                                    suggestions for provider data modernization.\n" +
            "                                </p>"
    }
]

export default HomeDocData;