import {TextField} from '@cmsgov/design-system'
import React from 'react'
import {maskValue, validateField} from '../../../utilities/field-input/validations'
import CreateLabel from '../InputLabel/InputLabel'

interface Props {
  type: string,
  inputId: string,
  label: string,
  ariaLabel?: string,
  placeholder?: string,
  required?: boolean,
  length?: number,
  maxLength: number,
  pattern?: string,
  disabled?: boolean,
  defaultValue?: string,
  className?: string,
  value?: string,
  errorMessage?: string,
  errorText?: string,
  onUpdate?: (id: string, value: string, error?: string, eventType?: string, hasMaxLength?: boolean) => void,
  onError?: (errorMessage: string) => void,
  onFocusLost: (id: string, value: number, error?: string, eventType?: string) => void,
  multiline?: boolean,
  rows?: number,
  title?: string,
  bypassValueManipulations?: boolean
}

interface State {
  pattern: string,
  label: string,
  maxLength?: number,
}

export class TextInput extends React.Component<Props, State> {
  constructor(props: any) {
    super(props)
    // Sets the maxLength to the length param if it exists, otherwise its the minlength/maxlength param
    const maxLength = this.props.length
        ? this.props.length
        : this.props.maxLength
    this.state = {
      maxLength: maxLength,
      pattern: this.props.pattern ? this.props.pattern : '[A-Za-z0-9]*',
      label: this.props.label
          ? `${this.props.label} ${this.props.required ? ' (Required)' : ''}`
          : '',
    }
  }

  /**
   * Performs updates to the value of the text field to ensure it fits our standards
   *
   * @param event - The event that triggered the update
   */
  textFieldOnChange(event: { currentTarget: { value: string; id: string }; type: string | undefined }) {
    let value = event.currentTarget.value
    let errorMessage = "";
    let hasMaxLength = false;

    // Strip characters which don't match the expected regex pattern
    if (this.props.pattern && value.match(this.props.pattern)) {
      let val = value.match(this.props.pattern)
      value = val ? val[0] : ''
    }

    // Strip special characters from CMG
    if (this.props.inputId === 'caseMixGroup') value = value.replace(/[^\w\s]/gi, '')

    // prevent value from exceeding max length
    if (value.length >= this.props.maxLength) {
      value = value.slice(0, this.props.maxLength)
      hasMaxLength = true;
    }

    // filter out value masking
    value = value.replace(/[$,]/g, '').toUpperCase()
    if (this.props.onUpdate && !!!this.props.bypassValueManipulations) {
      // if manipulations need to be bypassed pass event value in 
      this.props.onUpdate(event.currentTarget.id, this.props.bypassValueManipulations ? event.currentTarget.value : value, errorMessage, event.type, hasMaxLength)
    }
  }

  textFieldOnBlur(event: { currentTarget: { value: any; id: string }; type: string | undefined }) {
    let value = event.currentTarget.value
    let errorMessage
    // validate field and ensure a required field isn't empty
    if ((value.length === 0 && this.props.required) || (value.length > 0 && !validateField(this.props, value))) {
      errorMessage = this.props.errorText
    }
    if (this.props.onError && errorMessage) {
      this.props.onError(errorMessage)
    }

    if (this.props.onUpdate) {
      this.props.onUpdate(event.currentTarget.id, value, errorMessage, event.type)
    }

    if (this.props.onFocusLost) {
      this.props.onFocusLost(event.currentTarget.id, value, errorMessage, event.type)
    }
  }

  onKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {
    let value: any = event.currentTarget.value
    let errorMessage


    // validate field and ensure a required field isn't empty
    if ((value.length === 0 && this.props.required) || (value.length > 0 && !validateField(this.props, value))) {
      errorMessage = this.props.errorText
    }
    if (this.props.onError && errorMessage) {
      this.props.onError(errorMessage)
    }


    if (event.key === 'Enter') {
      this.props.onFocusLost(event.currentTarget.id, value, this.props.errorMessage, event.type)
    }
  }

  render(): JSX.Element {
    // Determines the size of the input field based upon the given maxLength
    let calculatedSize
    if (!this.state.maxLength) {
      calculatedSize = 12
    } else if (this.state.maxLength <= 2) {
      calculatedSize = 3
    } else {
      calculatedSize =
          this.state.maxLength % 2 === 0
              ? this.state.maxLength
              : this.state.maxLength + 1
    }
    return (
        <TextField
            type="text"
            ariaLabel={this.props.ariaLabel}
            value={maskValue(this.props.value, this.props.type)}
            defaultValue={this.props.defaultValue}
            style={{
              maxWidth: this.props.multiline ? undefined : `${calculatedSize}em`
            }}
            pattern={this.state.pattern}
            onChange={(event) => this.textFieldOnChange(event)}
            onBlur={(event) => this.textFieldOnBlur(event)}
            onKeyUp={(event) => this.onKeyUp(event)}
            placeholder={this.props.placeholder}
            name={this.props.inputId}
            id={this.props.inputId}
            label={CreateLabel({label: this.state.label})}
            required={this.props.required}
            errorMessage={this.props.errorMessage}
            className={`input-field ${this.props.className || ''}`}
            disabled={this.props.disabled}
            multiline={this.props.multiline}
            rows={this.props.rows}
            title={this.props.title}
        />
    )
  }
}

export default TextInput
