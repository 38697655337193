import React from 'react';
import './Announcements.scss';

interface Props {
    message: string
}

interface State {}

class Announcements extends React.Component<Props, State> {

    render() {
        return (
            <div className="visuallyhidden" aria-live="polite" aria-atomic="true">
                {this.props.message}
            </div>
            
        );
    }
}

export default Announcements;