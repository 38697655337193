// Allows the use of a utag object within the window object for the Teallium functionality
interface PdsWindow extends Window {
  utag?: any
}

const pdsWindow = window as PdsWindow;

/**
 * Reports a full google analytics event with all key details
 * 
 * @param action - The action being performed
 * @param label - The label to attach to the event
 * @param noninteraction - Is the action a non-interactive event
 * @param value - The value to attach to the event (optional)
 * @param retry - The number of times the event has tried to be sent (defaults to 0)
 */
function fullEvent(action: string, label: string, noninteraction: boolean, value?: number, retry = 0) {
  if (pdsWindow.utag) {
    const dataObj = {
      event_category: 'PDS-export',
      event_action: action,
      event_label: label,
      event_noninteraction: noninteraction ? 1 : 0,
      event_value: value
    }
    pdsWindow.utag.link(dataObj)
  } else if (retry < 10) {
    setTimeout(() => fullEvent(action, label, noninteraction, value, retry), (retry + 1) * 50)
  }
}

/**
 * The slimmed down event to send. Passes through to the fullEvent method.
 * Will not send a value with the event
 * 
 * @param action - The action being performed
 * @param label - The label to attach to the event
 * @param noninteraction - Is the action a non-interactive event
 */
function event(action: string, label: string, noninteraction: boolean) {
  fullEvent(action, label, noninteraction, undefined)
}

/**
 * Default button type to Button (CTA, Link are other's most likely )
 * 
 * @param e - The button event being passed in
 * @param action - The action taking place with the button event
 * @param primary - Is the event a CTA or Button event
 */
function buttonEvent(e: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>, action: string, primary: boolean) {
  fullEvent(action, `${primary ? 'CTA' : 'Button'}: ${e.currentTarget.textContent}`, false, undefined)
}

/**
 * Sending a link click events information through
 * 
 * @param e - The link event being passed in
 * @param action - The action taking place with the link event
 */
function linkEvent(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, action: string) {
  fullEvent(action, `Link: ${e.currentTarget.textContent}`, false, undefined)
}

/**
 * Calls the Telium view method for a given page path. This sends analytical information to Tealium
 * to allow us to track usage of the application by end users.
 * 
 * @param pathName - The page path of the page being viewed. 
 * @param retry - The number of times we retried calling the view method (optional, defaults to zero)
 */
function utagView(pathName: string, retry = 0) {
  if (pdsWindow.utag) {
    var pageName = ""
    var pageTitle = ""
    const language = "en"
    var siteEnvironment = ""
    var siteSection = ""

    if (window.location.hostname.includes("dev")) {
      siteEnvironment = "dev";
    } else if (window.location.hostname.includes("local")) {
      siteEnvironment = "dev";
    } else if (window.location.hostname.includes("impl")) {
      siteEnvironment = "qa";
    } else {
      siteEnvironment = "prod";
    }

    const home = "/#/"
    const download = "/#/Download"
    const support = "/#/Support"
    const documentation = "/#/Documentation"

    switch (pathName) {
      case home:
        pageName = "Home: PDS Landing Page"
        pageTitle = "PDS Homepage"
        siteSection = "homepage"
        break;
      case download:
        pageName = "Download: PDS Data Download page"
        pageTitle = "PDS Download"
        siteSection = "download"
        break;
      case support:
        pageName = "Support: PDS Support page"
        pageTitle = "PDS Support"
        siteSection = "support"
        break;
      case documentation:
        pageName = "Documentation: PDS Documentation page"
        pageTitle = "PDS Documentation"
        siteSection = "documentation"
        break;
    }

    const dataObj = {
      page_name: pageName,
      page_title: pageTitle,
      page_path: pathName,
      content_language: language,
      site_environment: siteEnvironment,
      site_section: siteSection,
      site_domain: window.location.hostname,
    }
    pdsWindow.utag.view(dataObj);
  } else if (retry < 10) {
    setTimeout(() => utagView(pathName, retry + 1), (retry + 1) * 50)
  }
}

export {
  event,
  buttonEvent,
  linkEvent,
  fullEvent,
  utagView
}
