import React from 'react';
import './ChangeLogBox.scss'
import ArrowUp from '../../../../images/Arrow-up.svg'
import {Button} from "@cmsgov/design-system";


interface Props {
    summary: string,
    date: string,
    releaseDetails: string[],
    id: string,
    onClick: Function,
    isOpen: boolean
}

interface State {

}

export class ChangeLogBox extends React.Component<Props, State> {

    render(): JSX.Element {
        const days_ago = "<1 day ago";
        const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds

        let newDate = new Date(Date.parse(this.props.date));
        let currDAte = new Date(Date.now());

        // @ts-ignore
        const diffDays = Math.round(Math.abs((currDAte - newDate) / oneDay)) - 1;

        const DetailList = this.props.releaseDetails.map((entry, index) => (
            <li key={index}>{entry}</li>
        ));


        return (
            <div className={`change-log-entry ${this.props.id}`}>
                <div className={"ds-l-container"}>
                    <div className={"ds-l-row"}>
                        <div className={"ds-l-col--12 ds-l-sm-col--11"}>
                            <b>{this.props.date}</b>
                        </div>
                        <div className={"ds-l-col--2 ds-u-float--right"}>
                            {
                                diffDays <= 1 ? (
                                    days_ago
                                ) : (
                                    `${diffDays} days ago`
                                )
                            }
                        </div>
                    </div>

                    {
                        this.props.isOpen ? (
                            <div className={"ds-l-row"}>
                                <div className={`ds-l-col--10`} id={`change-log-entry-${this.props.id}`}>
                                <p tabIndex={0}>This release includes:</p>
                                <ul tabIndex={0}>
                                        {DetailList}
                                    </ul>
                                </div>
                                <div className={"ds-l-col--2 ds-u-valign--bottom"}>
                                    <Button
                                        onClick={() => this.props.onClick(this.props.id, `change-log-entry-${this.props.id}`)}
                                        variation={"transparent"}
                                        size={"small"}
                                        className={`change-log-arrow-button change-log-arrow-button-entry-${this.props.id}`}
                                        aria-label={`Change log Entry ${parseInt(this.props.id,10) + 1} expandable button press enter to collapse`}
                                        tabIndex={0}
                                        aria-expanded={this.props.isOpen}
                                    >
                                        <img
                                            src={ArrowUp}
                                            alt={"Collapse Arrow"}
                                            className={"change-log-arrow "}
                                        />
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div className={"ds-l-row"}>
                                <div className={`ds-l-col--10`} tabIndex={0} id={`change-log-entry-${this.props.id}`}>
                                    {this.props.summary}
                                </div>
                                <div className={"ds-l-col--2"}>
                                    <Button
                                        onClick={() => this.props.onClick(this.props.id, `change-log-entry-${this.props.id}`)}
                                        variation={"transparent"}
                                        size={"small"}
                                        className={`change-log-arrow-button change-log-arrow-button-entry-${this.props.id}`}
                                        aria-label={`Change log Entry ${parseInt(this.props.id,10) + 1} expandable button press enter to expand`}
                                        tabIndex={0}
                                        aria-expanded={this.props.isOpen}
                                    >
                                        <img
                                            src={ArrowUp}
                                            alt={"Expand Arrow"}
                                            className={"change-log-arrow-close"}
                                        />
                                    </Button>
                                </div>
                            </div>
                        )
                    }

                </div>
            </div>
        );
    }
}