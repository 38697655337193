const OutpatientDataDictionary = [
    {
        fieldName: "providerCcn",
        dataType:"String",
        definition: [
            "Alpha-numeric 6-13 character provider number. Also known as the Oscar number previously."
        ]
    },
    {
        fieldName: "effectiveDate",
        dataType:"String",
        definition: [
            "Must be numeric, CCYYMMDD. This is the effective date of the provider's first OPPS period. " +
            "For subsequent OPPS periods, the effective date is the date of a change to the PROV file. " +
            "If a termination date is present for this record, the effective date must be equal to or less " +
            "than the termination date. " 
        ]
    },
    {
        fieldName: "nationalProviderIdentifier",
        dataType:"String",
        definition: [
            "Alpha-numeric 10 character provider number."
        ]
    },
    {
        fieldName: "fiscalYearBeginDate",
        dataType:"String",
        definition: [
            "Must be numeric, CCYYMMDD. Month: 01-12 Day: 01-31 The date must be greater than 19990630."
        ]
    },
    {
        fieldName: "exportDate (reportDate)",
        dataType:"String",
        definition: [
            "Must be numeric, CCYYMMDD. Date file created/run date of the PROV report for submittal to CMS CO."
        ]
    },
    {
        fieldName: "terminationDate",
        dataType:"String",
        definition: [
            "Must be numeric, CCYYMMDD. Must be zeroes or contain a termination date. (Once the official " +
            "“tie-out” notice from CMS is received). Must be equal to or greater than the effective date. " +
            "(Termination date is the date on which the reporting contractor ceased servicing he " +
            "provider in question). "
        ]
    },
    {
        fieldName: "waiverIndicator",
        dataType:"String",
        definition: [
            "Enter a “Y” or “N.” Y = waived (provider is not under OPPS) For End Stage Renal Disease (ESRD) " +
            "facilities provider waived blended payment, pay full PPS. N = not waived (provider is under OPPS) " +
            "For ESRD facilities provider did not waive blended payment. Pay according to transitional payment " +
            "method for ESRD PPS through 2013."
        ]
    },
    {
        fieldName: "intermediaryNumber",
        dataType:"String",
        definition: [
            "The intermediary number of the Medicare Administrative Contractor (MAC)."
        ]
    },
    {
        fieldName: "providerType",
        dataType:"String",
        definition: [
            "This identifies the provider type, such as short term facility, long-term, psychiatric, " +
            "rehabilitation facility, and so on."
        ]
    },
    {
        fieldName: "specialLocalityIndicator",
        dataType:"String",
        definition: [
            "Indicates the type of special locality provision that applies. For End Stage Renal Disease (ESRD) " +
            "facilities value “Y” equals low volume adjustment applicable. "
        ]
    },
    {
        fieldName: "changeCodeWageIndexReclassification",
        dataType:"String",
        definition: [
            "Enter “Y” if the hospital’s wage index location has been reclassified for the year. Enter “N” if " +
            "it has not been reclassified for the year. Adjust annually. Does not apply to ESRD Facilities. "
        ]
    },
    {
        fieldName: "msaActualGeographicLocation",
        dataType:"String",
        definition: [
            "Enter the appropriate code for MSA, 0040–9965, or the rural area, (blank) (blank) 2-digit numeric " +
            "State code, such as _ _ 3 6 for Ohio, where the facility is physically located. "
        ]
    },
    {
        fieldName: "msaWageIndexLocation",
        dataType:"String",
        definition: [
            "The appropriate code for the MSA, 0040-9965, or the rural area, (blank)(blank) (2 digit numeric " +
            "State code) such as _ _ 3 6 for Ohio, to which a hospital has been reclassified for wage index. " +
            "Leave blank or enter the actual location MSA if not reclassified. Does not apply to ESRD Facilities." 
        ]
    },
    {
        fieldName: "costOfLivingAdjustment",
        dataType:"Number",
        definition: [
            "This is the Cost of Living Adjustment (previously called Payment-to-Cost Ratio)."
        ]
    },
    {
        fieldName: "stateCode",
        dataType:"String",
        definition: [
            "Enter the 2-digit state where the provider is located. Enter only the first (lowest) code for a " +
            "given state. For example, effective October 1, 2005, Florida has the following State Codes: 10, " +
            "68 and 69. Contractors shall enter a “10” for Florida’s State Code. " +
            "List of valid State Codes is located in Pub. 10007, Chapter 2, Section 2779A1. "
        ]
    },
    {
        fieldName: "topsIndicator",
        dataType:"String",
        definition: [
            "Enter the code to indicate whether TOPs applies or not. Y = qualifies for TOPs; N = does " +
            "not qualify for TOPs "
        ]
    },
    {
        fieldName: "hospitalQualityIndicator",
        dataType:"String",
        definition: [
            "Hospital: Enter the code to indicate whether the hospital meets data submission criteria per HOP QDRP " +
            "requirements. 1 = Hospital quality reporting standards have been met or hospital is not required to " +
            "submit quality data (e.g., hospitals that are specifically excluded rom the IPPS or which are not paid " +
            "under the OPPS, including psychiatric, rehabilitation, long-term care and children’s and cancer " +
            "hospitals, Maryland hospitals, Indian Health Service hospitals, or hospital units; or hospitals that " +
            "are located in Puerto Rico or the U.S. territories). The reduction does not apply to hospices, CORFs, " +
            "HHAs, CMHCs, critical access hospitals or to any other provider type that is not a hospital. Blank = " +
            "Hospital does not meet criteria. Independent and Hospital-based End Stage Renal Disease " +
            "(ESRD)Facilities: Enter the code applicable to the ESRD Quality Incentive Program (QIP): Blank = " +
            "no reduction; 1 = ½ percent payment reduction; 2 = 1 percent payment reduction; 3 = 1 ½ percent " +
            "payment reduction; 4 = 2 percent payment reduction"
        ]
    },
    {
        fieldName: "operatingCostToChargeRatio",
        dataType:"Number",
        definition: [
            "Derived from the latest available cost report data. Does not apply to ESRD Facilities."
        ]
    },
    {
        fieldName: "cbsaActualGeographicLocation",
        dataType:"String",
        definition: [
            "00001-89999, or the rural area, (blank) (blank) (blank) 2 digit numeric State code such " +
            "as _ _ _3 6 for Ohio, where the facility is physically located." 
        ]
    },
    {
        fieldName: "cbsaWageIndexLocation",
        dataType:"String",
        definition: [
            "Enter the appropriate code for the CBSA, 0000189999, or the rural area, (blank)(blank)(blank) (2 digit " +
            "numeric State code) such as _ _ _ 3 6 for Ohio, to which a hospital has been reclassified due to its " +
            "prevailing wage rates. Leave blank or enter the Actual Geographic Location CBSA, if not reclassified. " +
            "Pricer will automatically default to the actual location CBSA if this field is left blank. Does not " +
            "apply to ESRD Facilities. "
        ]
    },
    {
        fieldName: "specialWageIndex",
        dataType:"Number",
        definition: [
            "Enter the special wage index that certain providers may be assigned. Enter zeroes unless the " +
            "Special Payment Indicator equals a “1” or “2.” "
        ]
    },
    {
        fieldName: "specialPaymentIndicator",
        dataType:"String",
        definition: [
            "The following codes indicate the type of special payment provision that applies. Blank = not " +
            "applicable; Y = reclassified; 1 = special wage index indicator; 2 = both special wage index " +
            "indicator and reclassified; D = Dual Reclassified"
        ]
    },
    {
        fieldName: "esrdChildrenQualityIndicator",
        dataType:"String",
        definition: [
            "Children’s Hospitals for End Stage Renal Disease (ESRD) Facilities: Enter the code applicable to the " +
            "ESRD Quality Incentive Program (QIP): Blank = no reduction; 1 = ½ percent payment reduction; 2 = 1 " +
            "percent payment reduction; 3 = 1 ½ percent payment reduction; 4 = 2 percent payment reduction"
        ]
    },
    {
        fieldName: "deviceCostToChargeRatio",
        dataType:"Number",
        definition: [
            "Derived from the latest available cost report data. Does not apply to ESRD Facilities. "
        ]
    },
    {
        fieldName: "countyCode",
        dataType:"String",
        definition: [
            "The County Code. Must be 5 numbers. "
        ]
    },
    {
        fieldName: "paymentCbsa",
        dataType:"String",
        definition: [
            "Enter the appropriate code for the CBSA, 0000189999, or the rural area, (blank)(blank)(blank) (2 digit " +
            "numeric State code) such as _ _ _ 3 6 for Ohio, to which a hospital has been reclassified due to its " +
            "prevailing wage rates. Leave blank or enter the Actual Geographic Location CBSA, if not reclassified. " +
            "Pricer will automatically default to the actual location CBSA if this field is left blank. Does not " +
            "apply to ESRD Facilities."
        ]
    },
    {
        fieldName: "paymentModelAdjustment",
        dataType:"Number",
        definition: [
            "Derived from payment model Technical Direction Letter."
        ]
    },
    {
        fieldName: "medicarePerformanceAdjustment",
        dataType:"Number",
        definition: [
            "Enter the MPA percentage calculated and published by the Centers for Medicare & Medicaid Services (CMS)."
        ]
    },
    {
        fieldName: "supplementalWageIndexIndicator",
        dataType:"String",
        definition: [
            "Enter the supplemental wage index flag: 1=Prior Year Wage Index 2=Future use 3=Future use " +
            "Enter blank if it does not apply. "
        ]
    },
    {
        fieldName: "supplementalWageIndex",
        dataType:"Number",
        definition: [
            "Enter the supplemental wage index that certain providers may be assigned. Enter zeroes if it " +
            "does not apply. "
        ]
    },
    {
        fieldName: "lastUpdated",
        dataType:"String",
        definition: [
            "A date formatted as YYYY-MM-DD, specifying the date that the provider record was last updated."
        ]
    },
    {
        fieldName: "carrierCode",
        dataType:"String",
        definition: [
            "The identification code of the medicare carrier for the provider."
        ]
    },
    {
        fieldName: "localityCode",
        dataType:"String",
        definition: [
            "The locality identification code for the area (or county) where the provider is located."
        ]
    }

]

export default OutpatientDataDictionary;
