/* eslint-disable react/jsx-no-target-blank */
import { Button } from '@cmsgov/design-system';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Announcements from '../../../../components/Announcements/Announcements';
import InputField from '../../../../components/InputField/InputField';
import FACILITY_TYPES_DATA from '../../../../data/download/FACILITY_TYPES_DATA';
import STATES_DATA from '../../../../data/download/STATES_DATA';
import './FilterModel.scss';

interface InputChoice {
    value: string,
    name: string,
    documentation: string,
    disabled?: boolean
}

interface Props {
    children?: string[],
    className?: string,
    heading?: string,
    onConfirm?: (event: any, filterState: State) => Promise<void>,
    filters: [],
    providerList: [],
    npiList?: [],
    stateList?: [],
    fileType: string,
    psfType: string,
    variation?: "primary" | "danger" | "success" | "transparent",
    size?: "narrow" | "wide" | "full",
    fromDate?: string,
    toDate?: string,
    facilityType?: string,
    updateFields: any,
    clearFilters: Function,
    filterByLatestRecord: boolean,
}

interface State {
    fromDate: string,
    fromBlur: boolean,
    toBlur: boolean,
    toDate: string,
    oscarNum: string,
    providerList: [],
    providerBlur: boolean,
    npiNum: string,
    npiList: [],
    npiBlur: boolean,
    facilityType: string,
    facilityTypeBlur: boolean,
    stateAbbr: string,
    stateList: [],
    facilityTypes: InputChoice[],
    states: InputChoice[],
    dateErrorMsg: string,
    providerNumErrorMsg: string,
    providerNumWarnMsg: string,
    npiErrorMsg: string,
    npiWarnMsg: string,
    announcementMessage: string,
    isValid: boolean,
}

export class FilterModel extends React.Component<Props, State> {
    constructor(props: any) {
        super(props)

        let errorMsg = this.validateFileType(this.props.filters, this.props.fileType, this.valueLookup("fromDate"), this.valueLookup("toDate"));
        let fromEffDate = (!!this.props.fromDate ? this.props.fromDate : "");
        let toEffDate = (!!this.props.toDate ? this.props.toDate : "");
        let facilityType = (!!this.props.facilityType ? this.props.facilityType : "");

        this.state = {
            fromDate: fromEffDate,
            fromBlur: (!!fromEffDate),
            toDate: toEffDate,
            toBlur: (!!toEffDate),
            oscarNum: "",
            providerBlur: false,
            providerList: (!!this.props.providerList ? this.props.providerList : []),
            npiNum: "",
            npiBlur: false,
            npiList: (!!this.props.npiList ? this.props.npiList : []),
            facilityType: facilityType,
            facilityTypeBlur: (!!facilityType),
            stateAbbr: "",
            stateList: (!!this.props.stateList ? this.props.stateList : []),
            facilityTypes: FACILITY_TYPES_DATA,
            states: STATES_DATA,
            dateErrorMsg: errorMsg,
            providerNumErrorMsg: "",
            providerNumWarnMsg: "",
            npiErrorMsg: "",
            npiWarnMsg: "",
            announcementMessage: "",
            isValid: true
        }
    }

    componentDidMount(): void {
     let fromDate =   document.getElementById('from-date')?.nextSibling as HTMLButtonElement;
     fromDate?.setAttribute('title', 'button_from_date');
     let toDate = document.getElementById('to-date')?.nextSibling as HTMLButtonElement;
     toDate?.setAttribute('title', 'button_to_date');
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.psfType === "outpatient" && this.state.facilityType !== "") {
            this.removeSubFilter(0, new CustomEvent("psf Type updated"), "facilityType")
        }
        if (this.props.filterByLatestRecord === true && this.state.fromDate !== "") {
            this.removeSubFilter(0, new CustomEvent("from Date updated"), "fromDate")
            this.setState({ dateErrorMsg: "" })
        }
        if (this.props.filterByLatestRecord === true && this.state.toDate !== "") {
            this.removeSubFilter(0, new CustomEvent("to Date updated"), "toDate")
            this.setState({ dateErrorMsg: "" })
        }
        if (this.props.fileType !== prevProps.fileType) {
            let errorMsg = this.validateFileType(this.props.filters, this.props.fileType, this.valueLookup("fromDate"), this.valueLookup("toDate"));
            this.setState({ dateErrorMsg: errorMsg });
        }
    }

    /**
     * Determines if a filter has already been used, and if so use that value for
     * the placeholder text.
     *
     * Only to be use with filters that can only have one instance.
     *
     * @param fieldName Name of Filter Filed
     * @param default Example text when filter is not in use
     * @returns Placeholder text to display in text field
     */
    valueLookup(fieldName: any) {
        if (!!this.props.filters.filter((filter: { param: string | string[]; }) => filter.param.includes(fieldName)).length) {
            // @ts-ignore
            let value = this.props.filters?.filter((filter: { param: string | string[]; }) => filter.param.includes(fieldName))[0].label;

            return value.slice(value.indexOf(":") + 2, value.length);
        } else {
            return "";
        }
    }

    documentationLookup(fieldName: string, dataList: InputChoice[]): string {
        let value = fieldName;

        dataList.forEach(element => {
            if (element.value === fieldName) {
                value = element.documentation;
            }
        });

        return value;
    }


    /**
     * Validates whether or not a field has exceed the number of occurences that can
     * be applied to the request
     * @returns boolean
     */
    hasFieldLimit(param: string, limit: number): boolean {
        let { filters } = this.props;
        // @ts-ignore
        let numOfParam = filters.filter(filter => filter.param.includes(param)).length;
        let appliedFiltersLimitExceeded = !!filters && numOfParam >= limit;

        switch (param) {
            case "fromDate":
                // @ts-ignore
                if (this.state.fromBlur && document.getElementById("from-date")?.disabled === false && this.props.filterByLatestRecord === false) {
                    // @ts-ignore
                    document.getElementById("from-date-filter").focus();
                } else if (this.props.filterByLatestRecord === true) {
                    return true;
                }
                return this.state.fromBlur;

            case "toDate":
                // @ts-ignore
                if (this.state.toBlur && document.getElementById("to-date")?.disabled === false && this.props.filterByLatestRecord === false) {
                    // @ts-ignore
                    document.getElementById("to-date-filter").focus();
                } else if (this.props.filterByLatestRecord === true) {
                    return true;
                }
                return this.state.toBlur;

            case "oscarNumber":
                // @ts-ignore
                if (((this.state.providerList.length) >= limit) && document.getElementById("oscar-number")?.disabled === false) {
                    // @ts-ignore
                    document.getElementById("oscar-number-filters").focus();
                }
                return ((this.state.providerList.length) >= limit);

            case "facilityType":
                // @ts-ignore
                if (this.state.facilityTypeBlur && document.getElementById("facility-type")?.disabled === false && this.state.psfType === "inpatient") {
                    // @ts-ignore
                    document.getElementById("facility-type-filter").focus();
                } else if (this.props.psfType === "outpatient") {
                    return true;
                }
                return this.state.facilityTypeBlur;

            case "npi":
                // @ts-ignore
                if (((this.state.npiList.length) >= limit) && document.getElementById("npi-number")?.disabled === false) {
                    // @ts-ignore
                    document.getElementById("npi-filters").focus();
                }
                return ((this.state.npiList.length) >= limit);

            case "state":
                // @ts-ignore
                if (((this.state.stateList.length) >= limit) && document.getElementById("state")?.disabled === false) {
                    // @ts-ignore
                    document.getElementById("state-filters").focus();
                }
                return ((this.state.stateList.length) >= limit);

            default:
                return appliedFiltersLimitExceeded;
        }
    }

    /***
     * Validation routine for To and From Date Filters
     */
    isValidDate(date: string) {
        const parts = date.split('/').map((p) => parseInt(p, 10));
        parts[0] -= 1;
        const d = new Date(parts[2], parts[0], parts[1]);
        return d.getMonth() === parts[0] && d.getDate() === parts[1] && d.getFullYear() === parts[2];
    }

    /***
     * Validation routine based on the field
     */
    fieldsAreValid(id: string, value: string): boolean {
        let isValid = true;
        let dateError = this.validateFileType(this.props.filters, this.props.fileType, this.state.fromDate, this.state.toDate);
        let providerError = "";
        let npiError = "";
        let currentDate = new Date();

        if (!!dateError) {
            isValid = false;
        }

        if (!!this.state.fromDate) {
            let fromDateObject = new Date(this.state.fromDate);
            let year = this.state.fromDate.slice(this.state.fromDate.length - 4)
            var fromYear = parseInt(year);

            if (! /^\d\d\/\d\d\/\d\d\d\d$/.test(this.state.fromDate)) {
                isValid = false;
                dateError = "Invalid From Effective Date: Must be MM/DD/YYYY Format.";
            }

            else if (!this.isValidDate(this.state.fromDate)) {
                isValid = false;
                dateError = "Invalid From Effective Date: Must be a valid date.";
            }

            else if (fromDateObject > currentDate) {
                isValid = false;
                dateError = "Invalid From Effective Date: Cannot be after Today's date.";
            }

            else if (fromYear < 1984) {
                isValid = false;
                dateError = "Invalid From Effective Date: Year must be 1984 or later.";
            }

        }

        if (!!this.state.toDate) {
            let toDateObject = new Date(this.state.toDate);
            let year = this.state.toDate.slice(this.state.toDate.length - 4)
            var toYear = parseInt(year);

            if (! /^\d\d\/\d\d\/\d\d\d\d$/.test(this.state.toDate)) {
                isValid = false;
                dateError = "Invalid To Effective Date: Must be MM/DD/YYYY Format.";
            }

            else if (!this.isValidDate(this.state.toDate)) {
                isValid = false;
                dateError = "Invalid To Effective Date: Must be a valid date.";
            }

            else if (toDateObject > currentDate) {
                isValid = false;
                dateError = "Invalid To Effective Date: Cannot be after Today's date.";
            }

            else if (toYear < 1984) {
                isValid = false;
                dateError = "Invalid To Effective Date: Year must be 1984 or later.";
            }

        }

        if (!!this.state.fromDate && !!this.state.toDate) {
            let fromDateObject = new Date(this.state.fromDate);
            let toDateObject = new Date(this.state.toDate);
            if (fromDateObject > toDateObject) {
                isValid = false;
                dateError = "Invalid Date Range: From Effective Date cannot be after To Effective Date ";
            }
        }

        if (!!this.state.oscarNum && (this.state.oscarNum.length > 13 || this.state.oscarNum.length < 6)) {
            isValid = false;
            providerError = "Invalid Oscar Number/ Provider Number/ CCN: Must be 6-13 characters.";
        }

        if (!!this.state.npiNum && this.state.npiNum.length !== 10) {
            isValid = false;
            npiError = "Invalid NPI: Must be exactly 10 characters long.";
        }

        this.setState({
            isValid: isValid,
            dateErrorMsg: dateError,
            providerNumErrorMsg: providerError,
            npiErrorMsg: npiError
        });

        return isValid;
    }

    /***
     * Validation routine based upon file type
     */
    validateFileType(filters: [], fileType: string, fromDate: string, toDate: string): string {
        let errorText = "";
        let dateRange = 3;
        /**
         * If no filters, throw error if wanting to download the excel file format
         */
        if (!filters?.length && !fromDate && !toDate) {
            if (fileType === 'excel' && !this.props.filterByLatestRecord) {
                errorText = "Specify Effective Date range (" + dateRange + " years max)";
            }
        } else {

            if (fileType === 'excel' && !this.props.filterByLatestRecord) {
                /**
                 * If fromDate has been specified, make sure the timeframe between fromDate and toDate or today's date is within range
                 */
                // @ts-ignore
                if (((!!filters.length && !!filters.filter(filter => filter.param.includes("fromDate")).length)) || !!fromDate) {
                    const fromDateFinal = new Date(fromDate);
                    let fromDateYear = fromDateFinal.getUTCFullYear();
                    let fromDatePlusDateRange = fromDateFinal.setFullYear(fromDateYear + dateRange);
                    let unixToDate: any = new Date(Date.parse(Date()));

                    // @ts-ignore
                    if (((!!filters.length && !!filters.filter(filter => filter.param.includes("toDate")).length) || !!toDate)) {
                        unixToDate = Date.parse(toDate);
                    } else {
                        errorText = "To Effective Date Must be Specified";
                    }

                    let dateDiff = unixToDate - fromDatePlusDateRange;


                    if (dateDiff > 0 && !errorText) {
                        errorText = "Effective Date range is over " + dateRange + " years";
                    }
                } else {
                    errorText = "From Effective Date Must be Specified";
                }
            }
        }

        return errorText;
    }

    /***
     * Function to be applied when focus is lost on an element
     */
    onBlur(id: string, value: any): void {
        this.updateFields(id, value);
        let isValid = this.fieldsAreValid(id, value);
        if (isValid) {
            this.showSubFilter(id, value);
        }


    }

    onUpdate(id: string, value: string): void {
        let warningMsg = "";

        switch (id) {
            case "oscar-number":
                if (value.length > 13) {
                    warningMsg = "Invalid Oscar Number/ Provider Number/ CCN: Must be 6-13 characters."
                }
                this.setState({ isValid: true, oscarNum: value, providerNumErrorMsg: warningMsg })
                break;

            case "npi-number":
                if (value.length > 10) {
                    warningMsg = "Invalid NPI: Must be exactly 10 characters long."
                }
                this.setState({ isValid: true, npiNum: value, npiErrorMsg: warningMsg })
                break;
        }
    }

    /***
     * Update the correct state field based upon the field ID
     */
    updateFields(id: string, value: string): void {
        switch (id) {
            case "from-date":
                if (!!value) {
                    this.setState({ fromDate: value, announcementMessage: `From Effective Date ${value} Filter Added` });
                }
                break;

            case "to-date":
                if (!!value) {
                    this.setState({ toDate: value, announcementMessage: `To Effective Date ${value} Filter Added` })
                }

                break;

            case "facility-type":
                if (!!value) {
                    this.setState({
                        isValid: true,
                        facilityType: value,
                        announcementMessage: `Facility Type ${this.documentationLookup(value, this.state.facilityTypes)} Filter Added`
                    })
                }
                break;

            case "oscar-number":
                if (!!value) {
                    this.setState({ oscarNum: value, announcementMessage: `Provider Number/CCN ${value} Filter Added` })
                }
                break;

            case "npi-number":
                if (!!value) {
                    this.setState({
                        npiNum: value,
                        announcementMessage: `National Provider Identifier ${value} Filter Added`
                    })
                }
                break;

            case "state":
                this.setState({
                    isValid: true,
                    stateAbbr: value,
                    announcementMessage: `State Filter for ${this.documentationLookup(value, this.state.states)} Added`
                })
                break;
        }
    }

    /**
     * Determines if the pre-applied filter should be visable or not
     */
    showSubFilter(id: string, value: any): void {
        switch (id) {
            case "from-date":
                if (!!this.state.fromDate) {
                    this.setState({ fromBlur: true })

                    if (!!this.state.toDate && ((!!!this.props.toDate && this.props.fileType === "excel") || !!this.state.announcementMessage.includes("To Effective"))) {
                        this.setState({ toBlur: true })
                    }
                    this.props.updateFields('fromDate', value);
                }

                break;

            case "to-date":
                if (!!this.state.toDate) {
                    this.setState({ toBlur: true })

                    if (!!this.state.fromDate && ((!!!this.props.fromDate && this.props.fileType === "excel") || !!this.state.announcementMessage.includes("From Effective"))) {
                        this.setState({ fromBlur: true })
                    }
                    this.props.updateFields('toDate', value);
                }

                break;

            case "facility-type":
                if (!!value) {
                    this.setState({ facilityTypeBlur: true })
                    this.props.updateFields('facilityType', value);
                }
                break;

            case "oscar-number":

                if (!!value) {
                    let list = this.addItemToList(this.state.providerList, value);

                    this.setState({ oscarNum: "", providerList: list, providerNumWarnMsg: "" })
                }
                break;

            case "npi-number":
                if (!!value) {
                    let list = this.addItemToList(this.state.npiList, value)

                    this.setState({ npiNum: "", npiList: list, npiWarnMsg: "" })
                }
                break;

            case "state":
                if (!!value) {
                    let list = this.addItemToList(this.state.stateList, value)

                    this.setState({ stateAbbr: "", stateList: list })
                }
                break;
        }
    }

    addItemToList(list: [], value: any): [] {
        let newList = list;
        let index = list.findIndex((element: string) => {
            return element === value
        });

        if (index < 0) {
            // @ts-ignore
            newList.push(value);
        }

        return newList;
    }

    /**
     * Removes a pre-applied filter and clears the field on screen
     * related to it.
     */
    removeSubFilter(index: number, event: any, fieldName: string): void {
        event.preventDefault();
        const showBlur = false;
        let newList: any = [];
        let value: any = "";


        switch (fieldName) {
            case "fromDate":
                this.setState({
                    fromBlur: showBlur,
                    fromDate: "",
                    announcementMessage: `From Effective Date ${this.state.fromDate} Filter Removed`
                })
                this.props.updateFields(fieldName, "");
                break;
            case "toDate":
                this.setState({
                    toBlur: showBlur,
                    toDate: "",
                    announcementMessage: `To Effective Date ${this.state.toDate} Filter Removed`
                })
                this.props.updateFields(fieldName, "");
                break;
            case "facilityType":
                this.setState({
                    facilityTypeBlur: showBlur,
                    facilityType: "",
                    announcementMessage: `Facility Type ${this.documentationLookup(this.state.facilityType, this.state.facilityTypes)} Filter Removed`
                })
                this.props.updateFields(fieldName, "");
                break;
            case "oscarNumber":
                if (!!this.state.providerList.length) {
                    newList = this.state.providerList;
                    value = newList.splice(index, 1);

                }

                this.setState({
                    providerList: newList,
                    announcementMessage: `Provider Number/CCN ${value} Filter Removed`
                });
                break;

            case "npi":
                if (!!this.state.npiList.length) {
                    newList = this.state.npiList;
                    value = newList.splice(index, 1);

                }

                this.setState({
                    npiList: newList,
                    announcementMessage: `National Provider Identifier ${value} Filter Removed`
                });
                break;

            case "state":
                if (!!this.state.stateList.length) {
                    newList = this.state.stateList;
                    value = newList.splice(index, 1);

                }

                this.setState({
                    stateList: newList,
                    announcementMessage: `State Filter for ${this.documentationLookup(value, this.state.states)} Removed`
                });
                break;
        }
    }

    onApply(event: any): void {
        if (!this.fieldsAreValid("", "")) {
            return;
        };

        let npiList = !!this.state.npiNum ? this.addItemToList(this.state.npiList, this.state.npiNum) : this.state.npiList
        let providerList = !!this.state.oscarNum ? this.addItemToList(this.state.providerList, this.state.oscarNum) : this.state.providerList
        let stateList = !!this.state.stateAbbr ? this.addItemToList(this.state.stateList, this.state.stateAbbr) : this.state.stateList

        this.setState({
            npiList: npiList,
            providerList: providerList,
            stateList: stateList
        })

        this.props.onConfirm!(event, this.state)
    }

    onPress(event: React.KeyboardEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLAnchorElement>, callfunction: Function) {
        if (event.key === 'Enter' || event.key === ' ') {
            callfunction();
        }
    }

    render(): JSX.Element {
        return (
            <div className="filter-model ds-l-container preview__grid ds-u-margin-top--0 ds-u-padding-bottom--2">
                <div className="ds-l-row">
                    <div className="ds-l-md-col--6">
                        {!this.props.filterByLatestRecord ? (<InputField
                            type="date"
                            label="From Effective Date"
                            inputId="from-date"
                            value={this.state.fromDate}
                            maxLength={10}
                            placeholder="mm/dd/yyyy"
                            disabled={this.hasFieldLimit("fromDate", 1)}
                            onUpdate={(value: any) => this.setState({
                                isValid: true,
                                fromDate: value,
                                fromBlur: false
                            })}
                            onFocusLost={(id: any, value: any) => this.onBlur(id, value)}
                            className={`${this.state.dateErrorMsg ? 'ds-c-alert-red' : ''}`}
                        />) : (<InputField
                            type="text"
                            label="From Effective Date"
                            inputId="from-date"
                            value={this.state.fromDate}
                            maxLength={10}
                            placeholder="mm/dd/yyyy"
                            disabled={this.hasFieldLimit("fromDate", 1)}
                            onUpdate={(id: any, value: any) => this.setState({
                                isValid: true,
                                fromDate: value,
                                fromBlur: false
                            })}
                            onFocusLost={(id: any, value: any) => this.onBlur(id, value)}
                            className={`${this.state.dateErrorMsg ? 'ds-c-alert-red' : ''}`}
                        />)}

                        <div id="from-date-filter"
                            className={`${!this.state.fromBlur ? 'no-sub-filters' : ''} filter-sub-options`}>
                            {
                                this.state.fromBlur ? (
                                    <div className="applied-sub-filter ds-u-margin-right--2 ds-u-margin-top--2">
                                        <div className="ds-u-float--left ds-u-margin-left--1">
                                            {this.state.fromDate} </div>
                                        <a href="/"
                                            className="close-button ds-u-float--right ds-u-margin-right--1 ds-u-color--primary-darker"
                                            onClick={(event) => this.removeSubFilter(0, event, "fromDate")}
                                            role="button"
                                            aria-label={`Remove From Effective Date ${this.state.fromDate}`}>

                                            <FontAwesomeIcon icon={faTimes} size="lg" /> </a>
                                    </div>
                                ) : null
                            }
                        </div>
                    </div>

                    <div className="ds-l-md-col--5">
                        {!this.props.filterByLatestRecord ? (<InputField
                            type="date"
                            label="To Effective Date"
                            inputId="to-date"
                            value={this.state.toDate}
                            maxLength={10}
                            placeholder="mm/dd/yyyy"
                            disabled={this.hasFieldLimit("toDate", 1)}
                            onUpdate={(value: any) => this.setState({
                                isValid: true,
                                toDate: value,
                                toBlur: false
                            })}
                            onFocusLost={(id: any, value: any) => this.onBlur(id, value)}
                            className={`${this.state.dateErrorMsg ? 'ds-c-alert-red' : ''}`}
                        />) : (
                            <InputField
                                type="text"
                                label="To Effective Date"
                                inputId="to-date"
                                value={this.state.toDate}
                                maxLength={10}
                                placeholder="mm/dd/yyyy"
                                disabled={this.hasFieldLimit("toDate", 1)}
                                onUpdate={(id: any, value: any) => this.setState({
                                    isValid: true,
                                    toDate: value,
                                    toBlur: false
                                })}
                                onFocusLost={(id: any, value: any) => this.onBlur(id, value)}
                                className={`${this.state.dateErrorMsg ? 'ds-c-alert-red' : ''}`}
                            />)}

                        <div id="to-date-filter"
                            className={`${!this.state.toBlur ? 'no-sub-filters' : ''} filter-sub-options`}>
                            {
                                this.state.toBlur ? (
                                    <div className="applied-sub-filter ds-u-margin-right--2 ds-u-margin-top--2">

                                        <div className="ds-u-float--left ds-u-margin-left--1">
                                            {this.state.toDate} </div>
                                        <a href="/"
                                            className="close-button ds-u-float--right ds-u-margin-right--1 ds-u-color--primary-darker"
                                            onClick={(event) => this.removeSubFilter(0, event, "toDate")}
                                            role="button"
                                            aria-label={`Remove To Effective Date ${this.state.toDate}`}>

                                            <FontAwesomeIcon icon={faTimes} size="lg" /> </a>
                                    </div>
                                ) : null
                            }
                        </div>
                    </div>
                </div>
                {!!this.state.dateErrorMsg ? (
                    <div className="ds-l-row">
                        <div className="ds-l-md-col--12">
                            <div className="" role="alert"
                                aria-label={`Date Error ${this.state.dateErrorMsg}`}>
                                <div className="">
                                    <p className="ds-c-alert__text">
                                        {this.state.dateErrorMsg}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                <div className="second-row ds-l-row">
                    <div className="ds-l-md-col--6">
                        <InputField
                            type="text"
                            label="By Oscar Number / Provider Number / CCN"
                            inputId="oscar-number"
                            hint="6 to 13 characters required. Up to 15 entries allowed."
                            value={this.state.oscarNum}
                            disabled={this.hasFieldLimit("oscarNumber", 15)}
                            length={28}
                            maxLength={15}
                            placeholder="Example: 010001"
                            onUpdate={(id: any, value: any, errorMessage: any, type: any) => this.onUpdate(id, value)}
                            onFocusLost={(id: any, value: any) => this.onBlur(id, value)}
                            className={`${this.state.providerNumErrorMsg || this.state.providerNumWarnMsg ? 'ds-c-alert-red' : ''}`}
                        />
                        <div id="oscar-number-filters"
                            className={`${!this.state.providerList.length ? 'no-sub-filters' : ''} filter-sub-options`}>
                            {
                                this.state.providerList.length ? this.state.providerList.map((provider: string, index: number) => (
                                    <div className="applied-sub-filter ds-u-margin-right--2 ds-u-margin-top--2"
                                        key={index}>
                                        <div aria-label={`Provider Number/CCN ${provider} Filter`}
                                            className="ds-u-float--left ds-u-margin-left--1">{provider}</div>
                                        <a href="/"
                                            className="close-button ds-u-float--right ds-u-margin-right--1 ds-u-color--primary-darker"
                                            onClick={(event) => this.removeSubFilter(index, event, "oscarNumber")}
                                            role="button"
                                            aria-label={`Remove Provider/CCN ${provider}`}>
                                            <FontAwesomeIcon icon={faTimes} size="lg" /> </a></div>
                                )) : null
                            }
                        </div>
                        {!!this.state.providerNumErrorMsg ? (
                            <div className="ds-l-row">
                                <div className="ds-l-md-col--12">
                                    <div className="" role="alert"
                                        aria-label={`Povider/CCN Error ${this.state.providerNumErrorMsg}`}>
                                        <div className="">
                                            <p className="ds-c-alert__text">
                                                {this.state.providerNumErrorMsg}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {!!this.state.providerNumWarnMsg ? (
                            <div className="ds-l-row">
                                <div className="ds-l-md-col--12">
                                    <div className="" role="alert"
                                        aria-label={`Povider/CCN has reached max length`}>
                                        <div className="">
                                            <p className="ds-c-alert__text">
                                                {this.state.providerNumWarnMsg}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className="ds-l-md-col--6 ds-u-padding-top--1">
                        <InputField
                            type="selection"
                            label="By Facility Type"
                            hint="1 entry allowed."
                            inputId="facility-type"
                            value={this.state.facilityType}
                            choices={this.state.facilityTypes}
                            disabled={this.hasFieldLimit("facilityType", 1)}
                            onUpdate={(id: any, value: any) => this.onBlur(id, value)}
                            onFocusLost={(id: any, value: any) => this.onBlur(id, value)}
                            className="dropdown"
                        />
                        <div id="facility-type-filter"
                            className={`${!this.state.facilityTypeBlur ? 'no-sub-filters' : ''} filter-sub-options`}>
                            {
                                this.state.facilityTypeBlur ? (
                                    <div className="applied-sub-filter ds-u-margin-right--2 ds-u-margin-top--2">
                                        <div
                                            aria-label={`${this.documentationLookup(this.state.facilityType, this.state.facilityTypes)}`}
                                            className="ds-u-float--left ds-u-margin-left--2">{this.state.facilityType}</div>
                                        <a href="/"
                                            className="close-button ds-u-float--right ds-u-margin-right--1 ds-u-color--primary-darker"
                                            onClick={(event) => this.removeSubFilter(0, event, "facilityType")}
                                            role="button"
                                            aria-label={`Remove Facility Type ${this.documentationLookup(this.state.facilityType, this.state.facilityTypes)}`}>
                                            <FontAwesomeIcon icon={faTimes} size="lg" /> </a></div>
                                ) : null
                            }
                        </div>
                    </div>
                </div>


                <div className="ds-l-row">
                    <div className="ds-l-md-col--6">
                        <InputField
                            type="number"
                            label="By National Provider Identifier (NPI)"
                            inputId="npi-number"
                            hint="10 characters required. Up to 15 entries allowed."
                            value={this.state.npiNum}
                            disabled={this.hasFieldLimit("npi", 15)}
                            length={28}
                            maxLength={12}
                            placeholder="Example: 1164403861"
                            onUpdate={(id: any, value: any, errorMessage: any, type: any) => this.onUpdate(id, value)}
                            onFocusLost={(id: any, value: any) => this.onBlur(id, value)}
                            className={`${this.state.npiErrorMsg || this.state.npiWarnMsg ? 'ds-c-alert-red' : ''}`}
                        />
                        <div id="npi-filters"
                            className={`${!this.state.npiList.length ? 'no-sub-filters' : ''} filter-sub-options`}>
                            {
                                this.state.npiList.length ? this.state.npiList.map((npi: any, index: any) => (
                                    <div className="applied-sub-filter ds-u-margin-right--2 ds-u-margin-top--2"
                                        key={index}>
                                        <div className="ds-u-float--left ds-u-margin-left--1">{npi}</div>
                                        <a href="/"
                                            className="close-button ds-u-float--right ds-u-margin-right--1 ds-u-color--primary-darker"
                                            onClick={(event) => this.removeSubFilter(index, event, "npi")}
                                            role="button"
                                            aria-label={`Remove NPI ${npi}`}>
                                            <FontAwesomeIcon icon={faTimes} size="lg" /> </a></div>
                                )) : null
                            }
                        </div>
                        {!!this.state.npiErrorMsg ? (
                            <div className="ds-l-row">
                                <div className="ds-l-md-col--12">
                                    <div className="" role="alert"
                                        aria-label={`NPI Error ${this.state.npiErrorMsg}`}>
                                        <div className="">
                                            <p className="ds-c-alert__text">
                                                {this.state.npiErrorMsg}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {!!this.state.npiWarnMsg ? (
                            <div className="ds-l-row">
                                <div className="ds-l-md-col--12">
                                    <div className="" role="alert"
                                        aria-label={`NPI has reached max length`}>
                                        <div className="">
                                            <p className="ds-c-alert__text">
                                                {this.state.npiWarnMsg}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className="ds-l-md-col--6 ds-u-padding-top--1">
                        <InputField
                            type="selection"
                            label="By State"
                            hint="Up to 5 entries allowed."
                            inputId="state"
                            value={this.state.stateAbbr}
                            choices={this.state.states}
                            disabled={this.hasFieldLimit("state", 5)}
                            onUpdate={(id: any, value: any) => this.onBlur(id, value)}
                            onFocusLost={(id: any, value: any) => this.onBlur(id, value)}
                            className="dropdown"
                        />
                        <div id="state-filters"
                            className={`${!this.state.stateList.length ? 'no-sub-filters' : ''} filter-sub-options`}>
                            {
                                this.state.stateList.length ? this.state.stateList.map((state: any, index: any) => (
                                    <div className="applied-sub-filter ds-u-margin-right--2 ds-u-margin-top--2"
                                        key={index}>
                                        <div aria-label={`${this.documentationLookup(state, this.state.states)}`}
                                            className="ds-u-float--left ds-u-margin-left--2">{state}</div>
                                        <a href="/"
                                            className="close-button ds-u-float--right ds-u-margin-right--1 ds-u-color--primary-darker"
                                            onClick={(event) => this.removeSubFilter(index, event, "state")}
                                            role="button"
                                            aria-label={`Remove state filtering ${this.documentationLookup(state, this.state.states)}`}>
                                            <FontAwesomeIcon icon={faTimes} size="lg" /> </a></div>
                                )) : null
                            }
                        </div>
                    </div>
                </div>



                <div className="ds-l-row ds-u-padding-top--3 ds-u-justify-content--end">
                    <div className="ds-u-padding-right--3">
                        <Button
                            key="confirm"
                            id="modal-confirm-button"
                            variation={'success'}
                            disabled={!this.state.isValid}
                            onMouseDown={(event) => this.onApply(event)}
                            onKeyDown={(event) => this.onApply(event)}
                            className="ds-u-margin--1 ds-u-padding--1 ds-u-padding-right--3 ds-u-padding-left--3"
                        >
                            Apply Filters
                        </Button>
                        <Button
                            key="clear"
                            id="modal-clear-button"
                            size={"small"}
                            className="ds-u-margin--1 ds-u-padding--1 ds-u-padding-right--3 ds-u-padding-left--3"
                            onClick={() => this.props.clearFilters()}
                        >
                            Clear Filters
                        </Button>
                    </div>
                </div>
                <Announcements
                    message={this.state.announcementMessage}
                />
            </div>
        )
    }
}