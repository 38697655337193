import React from 'react';

import { Header } from "../../components/Header/Header";
import { UsaBanner } from "@cmsgov/design-system";
import Footer from "../../components/Footer/Footer";
import { Body } from "./Body/Body";

import './Documentation.scss'
import { HeroBanner } from "../../components/HeroBanner/HeroBanner";
import { utagView } from "../../services/analytics-service"
import SkipLink from '../../components/SkipLink/SkipLink';

interface Props {
    type: string
}

interface State {
    pageType: string,
}

export class Documentation extends React.Component<Props, State> {
    constructor(Props: Props) {
        super(Props);
        this.state = {
            pageType: Props.type,
        };

    }
    async componentDidMount(): Promise<void> {
        var pathName = "/#/"+ this.state.pageType;
        utagView(pathName)
        try {
            var bannerElem = document.querySelector<HTMLElement>('.ds-c-usa-banner__button')!;
            bannerElem.setAttribute('tabindex', '0')
        } catch (e) {

        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        
        if(prevProps.type !== this.props.type){
            this.setState({pageType:this.props.type});
            this.componentDidMount();
        }
    }

    render(): JSX.Element {
        const HEADING = this.state.pageType === "dictionary" ? "Data Dictionary" : this.state.pageType === "docs" ? "Developer Documentation" : "Change Log"
        return (
            <div className={"documentation-page"}>
                <SkipLink className="primary ds-c-button ds-c-button--primary ds-c-button--big download-button ds-c-button ds-c-button--primary skip-link" skipTo='#main-content'>
                    <a role='button' href="#">Skip to main content</a>
                </SkipLink>
                <UsaBanner />
                <Header type={this.state.pageType}/>
                <HeroBanner
                    inputId={"doc-hero-banner"}
                    heading={HEADING}
                    variation={2}
                />
                <Body type={this.state.pageType} />
                <Footer />
            </div>

        );
    }

}