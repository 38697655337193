import React from 'react';
import { SideNav } from "../../../components/SideNav/SideNav";
import SupportSideNav from "../../../data/Support/SupportSideNav";
import SupportDocData from "../../../data/Support/SupportDocData";
import { TextBlock } from "../../../components/TextBlock/TextBlock";
import SupportDropDown from './DropDown';



interface Props {

}

interface State {
}

export class Body extends React.Component<Props, State> {
    
    render(): JSX.Element {
        return (
                <div role="main" className="support-body">
                    <section className="ds-l-container ds-base">
                        <div className="ds-l-row">
                            <aside  role = "complementary" className="ds-l-col--4 ds-u-margin-left--right ds-u-display--none ds-u-sm-display--block">
                                <SideNav
                                    sideNav={SupportSideNav} />
                            </aside>
                            <article className="ds-l-col--12 ds-l-sm-col--7">
                                <TextBlock
                                    DocData={SupportDocData} />
                            </article>
                            <SupportDropDown />
                        </div>
                    </section>
                </div>
        );
    }
}