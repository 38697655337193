const FACILITY_TYPES_DATA = [
    {
        value: "",
        name: "",
        documentation: "Select Facility Type",
        disabled: true
    },
    {
        value: "HHA",
        name: "HHA",
        documentation: "Home Health Agency Providers"
    },
    {
        value: "HOS",
        name: "HOS",
        documentation: "Hospice Providers"
    },
    {
        value: "INP",
        name: "INP",
        documentation: "Inpatient Providers"
    },
    {
        value: "IPF",
        name: "IPF",
        documentation: "Inpatient Psychiatric Facility Providers"
    },
    {
        value: "IRF",
        name: "IRF",
        documentation: "Inpatient Rehab Providers"
    },
    {
        value: "LTCH",
        name: "LTCH",
        documentation: "Long-Term Care Hospital Providers"
    },
    {
        value: "SNF",
        name: "SNF",
        documentation: "Skilled Nursing Facility Providers"
    }
]

export default FACILITY_TYPES_DATA;