import React from 'react';
import {Button} from "@cmsgov/design-system";
import BackToTop from "../../images/BackToTopButton.svg";
import ArrowDown from "../../images/Arrow-down1.svg";
import './BackToTopButton.scss'

interface Props {

}

interface State { }

export class BackToTopButton extends React.Component<Props, State> {

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let button = document.getElementById("back-to-top-button");

        if (!!button) {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                button.style.display = "block";
            } else {
                button.style.display = "none";
            }
        }
    }

    onClick(): void {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    render(): JSX.Element {

        return (
            <div className={"top-of-page-button"}>
                <Button
                    key={""}
                    id={"back-to-top-button"}
                    onClick={() => this.onClick()}
                    className={"back-to-top-button ds-u-font-size--xl"}
                ><img className={""} src={BackToTop} alt={"Back to Top Button"} />
                    <img className={"arrow-down"} src={ArrowDown} alt={"Back to Top Button"} />
                </Button>
            </div>
        );
    }

}