export interface FilterState{
    fromDate: string,
    toDate: string,
    providerList: [],
    npiList: [],
    facilityType: string,
    stateAbbr: string,
    facilityTypes: InputChoice[],
    states: InputChoice[],
    stateList: []
}

export interface InputChoice{
    label: string,
    value: string,
    defaultChecked?: boolean,
    disabled?: boolean
}

/***
 * Retrieves error text realted to CSV file format date range
 */
async function getErrorText(filters?:any, fileType?:string, fromDate?:string, toDate?:string, filterByLatestRecord?:boolean): Promise<string> {
    let errorText = "";
    let dateRange = 3;

    /**
     * If no filters, throw error if wanting to download the XLS file format
     */
    if (filters.length) {
        if (fileType === 'excel') {
            /**
             * If fromDate has been specified, make sure the timeframe between fromDate and toDate or today's date is within range
             */
            if ((!!filters.length && !!filters.filter((filter: { param: string | string[]; }) => filter.param.includes("fromDate")).length) || !!fromDate) {
                // @ts-ignore
                const fromDateFinal = new Date(fromDate);
                let fromDateYear = fromDateFinal.getUTCFullYear();
                let fromDatePlusDateRange = fromDateFinal.setFullYear(fromDateYear + dateRange);
                // @ts-ignore
                let unixToDate = Date.parse(new Date());

                if (((!!filters.length && !!filters.filter((filter: { param: string | string[]; }) => filter.param.includes("toDate")).length) || !!toDate)) {
                    // @ts-ignore
                    unixToDate = Date.parse(toDate);
                } else {
                    errorText = "To Effective Date Must be Specified";
                }

                let dateDiff = unixToDate - fromDatePlusDateRange;


                if (dateDiff > 0 && !errorText) {
                    errorText = "Effective Date range is over " + dateRange +" years";
                }
            } else if(!filterByLatestRecord) {
                errorText = "From Effective Date Must be Specified"; 
            }
        }
    }

    return errorText;
}

/***
 * Lock the download button when there are no filters on the excel download. 
 */
async function setDownloadLock(filters?:any, fileType?:string, filterByLatestRecord?:boolean): Promise<boolean> {
    let downloadLock = false;
    
    if (!filters.length) {
        if (fileType === 'excel' &&  !filterByLatestRecord) {
            downloadLock = true;
        }
    }

    return downloadLock;
}

async function modifyFilters(filters:[], filterState:FilterState): Promise<[]> {
    let { fromDate,
        toDate,
        providerList,
        npiList,
        facilityType,
        stateList } = filterState;

    
    /**
     * This checks the from date and reformats it for the label and the URL parameter
     */
    if (!!fromDate) {
        await removeFirstOccurence(filters, "fromDate");

        let fromDateObject = new Date(fromDate);
        // @ts-ignore
        let dateWithSlash = (fromDateObject.getMonth() + 1).toString().padStart(2,0) + "/" + fromDateObject.getDate().toString().padStart(2,0) + "/" + fromDateObject.getUTCFullYear();
        let dateWithDash = fromDateObject.getUTCFullYear() + "-" + ("0" + (fromDateObject.getMonth() + 1)).slice(-2) + "-" + ("0" + fromDateObject.getDate()).slice(-2);
        // @ts-ignore
        filters.push({label: `From: ${dateWithSlash}`, param: `fromDate=${dateWithDash}`});
    } else {
        await removeFirstOccurence(filters, "fromDate");
    }

    /**
     * This checks the to date and reformats it for the label and the URL parameter
     */
    if (!!toDate) {
        await removeFirstOccurence(filters, "toDate");

        let toDateObject = new Date(toDate);
        // @ts-ignore
        let dateWithSlash = (toDateObject.getMonth() + 1).toString().padStart(2,0) + "/" + toDateObject.getDate().toString().padStart(2,0) + "/" + toDateObject.getUTCFullYear();
        let dateWithDash = toDateObject.getUTCFullYear() + "-" + ("0" + (toDateObject.getMonth() + 1)).slice(-2) + "-" + ("0" + toDateObject.getDate()).slice(-2);
        // @ts-ignore
        filters.push({label: `To: ${dateWithSlash}`, param: `toDate=${dateWithDash}`});
    } else {
        await removeFirstOccurence(filters, "toDate");
    }

    /**
     * The rest of the fields will set the label and URL parameter for each field that is filled out
     */
    if (!!providerList.length) {
        await removeAllElementsForParam(filters, "oscarNumber");

        providerList.forEach(provider => {
            // @ts-ignore
            filters.push({label: `Oscar #: ${provider}`, param: `oscarNumber=${provider}`});
        })
    } else {
        await removeAllElementsForParam(filters, "oscarNumber");
    }

    if (!!npiList.length) {
        await removeAllElementsForParam(filters, "npi");

        npiList.forEach(npi => {
            // @ts-ignore
            filters.push({label: `NPI #: ${npi}`, param: `npi=${npi}`});
        });
    } else {
        await removeAllElementsForParam(filters, "npi");
    }

    if (!!facilityType) {
        await removeFirstOccurence(filters, "facilityType");

        // @ts-ignore
        filters.push({label: `Facility Type: ${facilityType}`, param: `facilityType=${facilityType}`});
        
        
    } else {
        await removeFirstOccurence(filters, "facilityType");
    }

    if (!!stateList) {
        await removeAllElementsForParam(filters, "state");

        stateList.forEach(state => {
            // @ts-ignore
            filters.push({label: `State: ${state}`, param: `state=${state}`});
        });
    } else {
        await removeAllElementsForParam(filters, "state");
    }

    return filters;
}

async function removeAllElementsForParam(elementList: [], paramName: string):Promise<[]> {
    if (!!elementList.length){
        for (let index = elementList.length; index >= 0; index--) {
            // @ts-ignore
            if (elementList[index]?.param.includes(paramName)) {
                elementList.splice(index, 1);
            }
        }

        return elementList;
    } else {
        return [];
    }

    
}

async function removeFirstOccurence(elementList: [], paramName: string):Promise<[]> {
    if (!!elementList.length){
        // @ts-ignore
        if (!!elementList.filter(element => element.param.includes(paramName)).length) {
        // @ts-ignore
            let index = elementList.findIndex(element => element.param.includes(paramName));
        elementList.splice(index, 1);

        }

        return elementList;
    } 

    return [];
}
  
  // sort by key
async function sortItems(items: []): Promise<[]> {
    return items.sort((a,b) => {
        // @ts-ignore
        let nameA = a.label.toUpperCase(); // ignore upper and lowercase
        // @ts-ignore
        let nameB = b.label.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      
        // names must be equal
        return 0;
    })
}

export { getErrorText, setDownloadLock, modifyFilters, sortItems };

