const STATES_DATA = [
    {
        value: "",
        name: "",
        documentation: "Select State",
        disabled: true
    },
    {
        value: "AL",
        name: "AL",
        documentation: "Alabama"
    },
    {
        value: "AK",
        name: "AK",
        documentation: "Alaska"
    },
    {
        value: "AS",
        name: "AS",
        documentation: "American Samoa"
    },
    {
        value: "AZ",
        name: "AZ",
        documentation:"Arizona"
    },
    {
        value: "AR",
        name: "AR",
        documentation: "Arkansas"
    },
    {
        value: "CA",
        name: "CA",
        documentation: "California"
    },
    {
        value: "CO",
        name: "CO",
        documentation: "Colorado"
    },
    {
        value: "CT",
        name: "CT",
        documentation: "Connecticut"
    },
    {
        value: "DE",
        name: "DE",
        documentation: "Delaware"
    },
    {
        value: "DC",
        name: "DC",
        documentation: "District of Columbia"
    },
    {
        value: "FL",
        name: "FL",
        documentation: "Florida"
    },
    {
        value: "GA",
        name: "GA",
        documentation: "Georgia"
    },
    {
        value: "GU",
        name: "GU",
        documentation: "Guam"
    },
    {
        value: "HI",
        name: "HI",
        documentation: "Hawaii"
    },
    {
        value: "ID",
        name: "ID",
        documentation: "Idaho"
    },
    {
        value: "IL",
        name: "IL",
        documentation: "Illinois"
    },
    {
        value: "IN",
        name: "IN",
        documentation: "Indiana"
    },
    {
        value: "IA",
        name: "IA",
        documentation: "Iowa"
    },
    {
        value: "KS",
        name: "KS",
        documentation: "Kansas"
    },
    {
        value: "KY",
        name: "KY",
        documentation: "Kentucky"
    },
    {
        value: "LA",
        name: "LA",
        documentation: "Louisiana"
    },
    {
        value: "ME",
        name: "ME",
        documentation: "Maine"
    },
    {
        value: "MD",
        name: "MD",
        documentation: "Maryland"
    },
    {
        value: "MA",
        name: "MA",
        documentation: "Massachusetts"
    },
    {
        value: "MI",
        name: "MI",
        documentation: "Michigan"
    },
    {
        value: "MN",
        name: "MN",
        documentation: "Minnesota"
    },
    {
        value: "MS",
        name: "MS",
        documentation: "Mississippi"
    },
    {
        value: "MO",
        name: "MO",
        documentation: "Missouri"
    },
    {
        value: "MT",
        name: "MT",
        documentation: "Montana"
    },
    {
        value: "NE",
        name: "NE",
        documentation: "Nebraska"
    },
    {
        value: "NV",
        name: "NV",
        documentation: "Nevada"
    },
    {
        value: "NH",
        name: "NH",
        documentation: "New Hampshire"
    },
    {
        value: "NJ",
        name: "NJ",
        documentation: "New Jersey"
    },
    {
        value: "NM",
        name: "NM",
        documentation: "New Mexico"
    },
    {
        value: "NY",
        name: "NY",
        documentation: "New York"
    },
    {
        value: "NC",
        name: "NC",
        documentation: "North Carolina"
    },
    {
        value: "ND",
        name: "ND",
        documentation: "North Dakota"
    },
    {
        value: "MP",
        name: "MP",
        documentation: "Northern Marianas Islands"
    },
    {
        value: "OH",
        name: "OH",
        documentation: "Ohio"
    },
    {
        value: "OK",
        name: "OK",
        documentation: "Oklahoma"
    },
    {
        value: "OR",
        name: "OR",
        documentation: "Oregon"
    },
    {
        value: "PA",
        name: "PA",
        documentation: "Pennsylvania"
    },
    {
        value: "PR",
        name: "PR",
        documentation: "Puerto Rico"
    },
    {
        value: "RI",
        name: "RI",
        documentation: "Rhode Island"
    },
    {
        value: "SC",
        name: "SC",
        documentation: "South Carolina"
    },
    {
        value: "SD",
        name: "SD",
        documentation: "South Dakota"
    },
    {
        value: "TN",
        name: "TN",
        documentation: "Tennessee"
    },
    {
        value: "TX",
        name: "TX",
        documentation: "Texas"
    },
    {
        value: "UT",
        name: "UT",
        documentation: "Utah"
    },
    {
        value: "VT",
        name: "VT",
        documentation: "Vermont"
    },
    {
        value: "VA",
        name: "VA",
        documentation: "Virginia"
    },
    {
        value: "VI",
        name: "VI",
        documentation: "Virgin Islands"
    },
    {
        value: "WA",
        name: "WA",
        documentation: "Washington"
    },
    {
        value: "WV",
        name: "WV",
        documentation: "West Virginia"
    },
    {
        value: "WI",
        name: "WI",
        documentation: "Wisconsin"
    },
    {
        value: "WY",
        name: "WY",
        documentation: "Wyoming"
    }

]

export default STATES_DATA;