const SupportFAQ = [{
        heading: 'What Does the Inpatient PSF data download include?',
        answer: " We use the term 'Inpatient' to refer to all 7 inpatient facilities types that are processed by\n" +
            " the Medicare pricers: home health agencies (HHA), hospice, acute care inpatient (IPPS),\n" +
            " inpatient psychiatric facilities (IPF), inpatient rehabilitation facilities (IRF), long term care\n" +
            " hospitals (LTCH), and skilled nursing facilities (SNF).  If you download the Inpatient PSF, the\n" +
            " data will include provider records for all of these facilities.  You can also filter by facility type\n" +
            " if you would like a facility-specific download.\n",
        ariaLabel: "Inpatient PSF data download information button"
    },

    {
        heading: 'What does the historical provider specific file mean?',
        answer: "Our service allows you to download all-time PSF data for Medicare provider records from previous\n" +
            "years, dating back to the 1980s in some cases. If you would like a more targeted date set, use the\n" +
            "'From Effective Date' and 'To Effective Date' filters to narrow the date range of date returned.\n",
        ariaLabel: "Historical provider specific file information button"
    },

    {
        heading: 'How current is the data I downloaded?',
        answer: "The PDS Site allows users to download PSF Data on demand from CMS in near real-time.\n" +
            "Please refer to the 'reportDate' of any file download to see when the data was pulled.\n",
        ariaLabel: "Current data downloaded information button"
    },
]

export default SupportFAQ;