import React from 'react';
import CloseButton from '../../images/close-grey.svg'
import './Banner.scss'


interface Props {
    inputId: string,
    text: string
}

interface State {
    showBanner: boolean
}

export class Banner extends React.Component<Props, State> {
    constructor(Props: Props | Readonly<Props>) {
        super(Props);

        this.state = {
            showBanner: true
        };
    }

    toggleBanner(): void {
        this.setState({ showBanner: !this.state.showBanner });

        //Adjust the height of the Hero Banner and Body when removing Welcome Banner
        if (document?.getElementsByClassName('hero-banner').length > 0 && document.getElementsByClassName('home-body')) {
            Array.from(document.getElementsByClassName('hero-banner') as HTMLCollectionOf<HTMLElement>)[0].style.top = '32px';
            Array.from(document.getElementsByClassName('home-body') as HTMLCollectionOf<HTMLElement>)[0].style.marginTop = '88px';
        }
    }


    render(): JSX.Element {
        return (
            <div className={`ds-u-sans ${this.props.inputId} ${this.state.showBanner ? 'banner ': ''} ${this.props.inputId}`}>
                {
                    this.state.showBanner ? (
                        <div className={"banner-body ds-u-display--flex"}>
                            <div role='alert' className={"banner-text"} dangerouslySetInnerHTML={{ __html: this.props.text }} tabIndex={0} />
                            <button
                                className={"closeButton ds-u-margin-left--auto ds-u-border--0"}
                                onClick={() => this.toggleBanner()} >
                                <img src={CloseButton} alt={"Close Welcome Banner Button"} />
                            </button>
                        </div>
                    ) : null
                }

            </div>
        );
    }
}