import React from 'react';
import { Header } from "../../components/Header/Header";
import { UsaBanner } from "@cmsgov/design-system";
import Footer from "../../components/Footer/Footer";
import { HeroBanner } from "../../components/HeroBanner/HeroBanner";
import { Body } from "./Body/Body";
import './Support.scss'
import { utagView } from "../../services/analytics-service"
import SkipLink from '../../components/SkipLink/SkipLink';


interface Props {
}

interface State { }

export class Support extends React.Component<Props, State> {

    onClick(event: any) {
        event.preventDefault();
        window.location.href = '/';
    }

    async componentDidMount(): Promise<void> {
        var pathName = "/#/Support"
        utagView(pathName)
        try{
            var bannerElem=document.querySelector<HTMLElement>('.ds-c-usa-banner__button')!;
            bannerElem.setAttribute('tabindex', '0')
        } catch(e){

        }
    }

    render(): JSX.Element {
        return (
            <div
                className={"support-page"}>
                <SkipLink className="primary ds-c-button ds-c-button--primary ds-c-button--big download-button ds-c-button ds-c-button--primary skip-link" skipTo='#main-content'>
                    <a role='button' href="#">Skip to main content</a>
                </SkipLink>
                <UsaBanner />
                <Header />
                <HeroBanner
                    inputId={"support-hero-banner"}
                    variation={3}
                    heading={"Support"}
                />
                <Body />
                <Footer />
            </div>
        )
    }



} 