import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faCheckCircle,
    faChevronDown,
    faCloudDownloadAlt,
    faComments,
    faCopy,
    faPlus
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { HashRouter, Redirect, Route, RouteProps, Switch } from "react-router-dom";
import { Home } from '../../pages/Home/Home';
import { Download } from '../../pages/DataDownload/Download';
import { Documentation } from "../../pages/Documetation/Documentation";
import { Support } from '../../pages/Support/Support';
import AlertBox from '../AlertBox/AlertBox';


library.add(faPlus, faChevronDown, faCheckCircle, faCloudDownloadAlt, faComments, faCopy);

interface Props { }

interface State {
    apiKey: string,
    showNav: boolean,
    audioMessage: string,
    focusElement: string,
    location?: Location,
}

class App extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props)

        this.state = {
            showNav: false,
            audioMessage: '',
            focusElement: '',
            apiKey: ''
        }
    }

    /**
     * Generates common routes required for each page.
     * Using exact routes simplifies the route patterns and reduces
     * need for route guarding/error handling.
     *
     * @param parentRoute - the base page route
     * @param component - the page component needed to render the route
     * @param props - properties to be passed along with the component
     */
    generateDynamicPDSRoutes(parentRoute: string, component: any, props: any): React.CElement<RouteProps, Route<RouteProps>>[] {
        const dynamicRoutes = [];
        dynamicRoutes.push(
            React.createElement(Route, {
                key: window.location.hash,
                path: `${parentRoute}`,
                render: () => {
                    if (!!component) {
                        // the parent route can be rendered normally
                        return React.createElement(component, props)
                    } else {
                        // redirect user to landing page
                        return React.createElement(Redirect, { to: '/' })
                    }
                },
                exact: true
            }))
        return dynamicRoutes
    }

    render(): JSX.Element {
        // @ts-ignore
        return (
            <section className="root">
                <HashRouter>
                    <Switch>
                        {this.generateDynamicPDSRoutes('/', Home, this.props)}
                        {this.generateDynamicPDSRoutes('/Data_dictionary', Documentation, {...this.props, type:"dictionary"})}
                        {this.generateDynamicPDSRoutes('/Developer_documentation', Documentation, {...this.props, type:"docs"})}
                        {this.generateDynamicPDSRoutes('/Change_log', Documentation, {...this.props, type:"log"})}
                        {this.generateDynamicPDSRoutes('/Support', Support, this.props)}
                        {this.generateDynamicPDSRoutes('/Download', Download, this.props)}
                    </Switch>
                </HashRouter>
                {
                    window.location.hostname.includes("pds.mps.") ?
                        '' : <AlertBox />
                }

            </section>
        )
    }
}

export default App;
