import {ChoiceList} from '@cmsgov/design-system'
import {ChoiceProps} from '@cmsgov/design-system/dist/types/ChoiceList/ChoiceList'
import React from 'react'
import CreateLabel from '../InputLabel/InputLabel'

interface Props {
  inputId: string,
  ariaLabel?: string,
  label?: string,
  required?: boolean,
  disabled?: boolean,
  choices: ChoiceProps[],
  multiple?: boolean,
  className?: string,
  errorMessage?: string[],
  value?: string,
  onError?: (errorMessage:string[])=>void,
  onUpdate?: (id:string, value:string|number, error?:string[], eventType?:string) =>  void
}

interface State{
  label: string,
  changed: boolean
}

interface State {
  label: string
}

export class ChoiceListInput extends React.Component<Props, State> {
  constructor(props: any) {
    super(props)
    this.state = {
      label: this.props.label
        ? `${this.props.label} ${this.props.required ? ' (Required)' : ''}` : '',
      changed: false
    }
  }

  /**
   * Calls updates when a choice has been selected from a choice list
   * 
   * @param component - The component containing the choice list
   * @param event - The event that triggered the update
   */
  choiceListOnChange(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const value = event.currentTarget.value
    if (this.props.onError && this.props.errorMessage) {
      this.props.onError(this.props.errorMessage)
    }
    event.type = 'select'
    if (this.props.onUpdate) {
      this.props.onUpdate(event.currentTarget.name || event.currentTarget.id, value, this.props.errorMessage, event.type)
    }
    if (!this.state.changed) {
      this.setState({ changed: true })
    }
  }

  render(): JSX.Element {
    const emptyValue = (this.props.value === '' || this.props.value === undefined || this.props.value === null)
    if (this.state.changed && emptyValue) {
      this.setState({ changed: false })
    }
    return (
      <ChoiceList
        key={`${(this.state.changed && emptyValue)}`}
        size="small"
        type={this.props.multiple ? 'checkbox' : 'radio'}
        choices={this.props.choices}
        onChange={(event) => this.choiceListOnChange(event)}
        name={this.props.inputId}
        label={CreateLabel({label: this.props.label || ''})}
        errorMessage={this.props.errorMessage}
        className={`input-field ${this.props.className || ''}`}
        disabled={this.props.disabled}
      />
    )
  }
}

export default ChoiceListInput
