import { SingleInputDateField } from '@cmsgov/design-system'
import React from 'react'
import { maskValue, padValue, validateField } from '../../../utilities/field-input/validations'
import CreateLabel from '../InputLabel/InputLabel'

interface Props {
  inputId: string,
  label: string,
  ariaLabel?: string,
  required?: boolean,
  disabled?: boolean,
  className?: string,
  length?: number,
  maxLength?: number,
  value?: string,
  autoFocus?: boolean,
  errorMessage?: string,
  errorText?: string,
  onFocusLost: (id: string, value: string, error?: string, eventType?: string) => void,
  onUpdate: (value: string) => void,
  onError?: (errorMessage: string) => void
}

interface State {
  label: string,
  maxLength?: number,
}

export class DateInput extends React.Component<Props, State> {
  constructor(props: any) {
    super(props)

    const maxLength = this.props.length
      ? this.props.length
      : this.props.maxLength

    this.state = {
      maxLength: maxLength,
      label: this.props.label
        ? `${this.props.label} ${this.props.required ? ' (Required)' : ''}`
        : '',
    }
  }

 

  dateFieldOnChange(updatedValue: string) {
    this.props.onUpdate(updatedValue);
  }

  dateFieldOnBlur(event: { currentTarget: { value: string; id: string }; type: string | undefined }) {
    let value = this.processDate(event.currentTarget.value);

    if (this.props.onFocusLost) {
      this.props.onFocusLost(event.currentTarget.id, value, event.type)
    }
  }

  processDate(value: string): string {
    let date = value;

    if (date.length > 0) {
      // Dates should be reformatted with leading zeroes for days/months
      const parts = date.split('/')
      date = `${padValue(parts[0] || '', 2)}/${padValue(parts[1] || '', 2)}/${padValue(parts[2] || '', 4)}`
      return date;
    }
    return "";
  }

  render() {
   
    return (

      <SingleInputDateField
        name={this.props.inputId}
        id={this.props.inputId}
        label={CreateLabel({ label: this.state.label })}
        value={""+maskValue(this.props.value, 'date')}
        onChange={(value) => this.dateFieldOnChange(value)}
        onBlur={(event) => this.dateFieldOnBlur(event)}
        errorPlacement="bottom"
        errorMessage={this.props.errorMessage}
        fromDate={new Date("01/01/1985")}
        toDate={new Date()}
        className={`input-field ${this.props.className || ''}`}
      />
    )
  }
}

export default DateInput
