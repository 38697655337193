import { Choice } from '@cmsgov/design-system'
import { ChoiceProps } from '@cmsgov/design-system/dist/types/ChoiceList/Choice'
import React from 'react'
import CreateLabel from '../InputLabel/InputLabel'

interface Props {
    inputId: string,
    ariaLabel?: string,
    label: string,
    checked?: boolean
    required?: boolean,
    disabled?: boolean,
    className?: string,
    errorMessage?: string[],
    hint?: string
    value: boolean,
    onError?: (errorMessage: string[]) => void,
    onUpdate?: (id: string, value: boolean, error?: string[], eventType?: string) => void
}

interface State {
    label: string,
    checked: boolean
}

export class ChoiceInput extends React.Component<Props, State> {
    constructor(props: any) {
        super(props)
        this.state = {
            label: this.props.label,
            checked: this.props.value
        }
    }

    /**
     * Calls updates when a choice has been selected from a choice list
     * 
     * @param component - The component containing the choice list
     * @param event - The event that triggered the update
     */
    choiceOnChange(event: React.ChangeEvent<HTMLInputElement>) {
        const value = !this.state.checked;

        if (this.props.onError && this.props.errorMessage) {
            this.props.onError(this.props.errorMessage)
        }
        if (this.props.onUpdate) {
            this.props.onUpdate(event.currentTarget.name || event.currentTarget.id, value, this.props.errorMessage, event.type)
        }
        this.setState({ checked: value });
    }

    render(): JSX.Element {
        return (
            <Choice
                size="small"
                type='checkbox'
                value={`${this.state.checked}`}
                checked={this.state.checked}
                onChange={(event) => this.choiceOnChange(event)}
                name={this.props.inputId}
                label={CreateLabel({ label: this.props.label || '' })}
                className={`input-field ${this.props.className || ''}`}
                hint={this.props.hint}
            />
        )
    }
}

export default ChoiceInput
