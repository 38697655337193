import React from 'react'
import {ChoiceListInput} from '../InputComponents/ChoiceListInput/ChoiceListInput'
import {DateInput} from '../InputComponents/DateInput/DateInput'
import {DropDown} from '../InputComponents/DropDown/DropDown'
import {InputList} from '../InputComponents/InputList/InputList'
import {NumericInput} from '../InputComponents/NumericInput/NumericInput'
import {TextInput} from '../InputComponents/TextInput/TextInput'
import './InputField.scss'
import { ChoiceInput } from '../InputComponents/ChoiceInput/ChoiceInput'

export interface InputChoice{
  group?: undefined
  label?: string,
  value?: string,
  defaultChecked?: boolean,
  disabled?: boolean,
  name?: string,
  documentation?: string
}

export interface InputProps {
  multiple?: boolean,
  type: string,
  label?: string,
  ariaLabel?: string,
  placeholder?: string,
  inputId?: string,
  required?: boolean,
  length?: number,
  hint?: string,
  maxLength?: number,
  defaultValue?: string | number,
  value?: string | string[] | boolean,
  onUpdate?: Function,
  onFocusLost?: Function,
  onHelp?: Function,
  errorMessage?: string | string[],
  choices?: InputChoice[],
  size?: string,
  disabled?: boolean,
  className?: string,
  autoFocus?: boolean,
  title?: string,
}

export interface InputState { }
/**
 * Determines the type of input field to create based upon the type and if its marked as multiple
 * @param {*} props - Contains the fields type and multiple, as well as the params to pass along to the input field being created
 */
export class InputField extends React.Component<InputProps, InputState>{
  constructor(props: any) {
    super(props)
    this.state = {}
  }

  render(): JSX.Element | null{
    if (this.props.multiple && !['list'].includes(this.props.type)) {
      return React.createElement(InputList, this.props)
    }
    switch (this.props.type) {
      case 'currency':
      case 'number':
      case 'decimal':
        return React.createElement(NumericInput, this.props)
      case 'date':
        return React.createElement(DateInput, this.props)
      case 'text':
        return React.createElement(TextInput, this.props)
      case 'list':
        return React.createElement(ChoiceListInput, this.props)
      case 'selection':
        return React.createElement(DropDown, this.props)
      case 'individualRadio':
        return React.createElement(ChoiceInput, this.props)
      default:
        return null
    }
  }
}

export default InputField
