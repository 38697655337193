const SupporDoctData = [{
        heading: "Get in Touch",
        id: "get-in-touch",
        text: " Send us an Email at <a href=\"mailto:ModernizingMedicare@cms.hhs.gov?subject=Provider%20Specific%20File%20Feedback\">ModernizingMedicare@cms.hhs.gov</a>\n" +
            "  <ul>" +
            "   <li>" +
            "    We'd love to hear from you! Was this new service useful to you? What can we\n" +
            "   improve? No ask is too small. Our goal is to enhance your experience."

    },
    {
        heading: "FAQs",
        id: "FAQs",
        text: "null"
    }
]

export default SupporDoctData;