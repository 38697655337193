import React from 'react';
import './Body.scss'
import { SideNav } from "../../../components/SideNav/SideNav";
import DocSideNav from "../../../data/documentation/DocSideNav";
import APIEndpoint from '../../../images/API-Endpoints.svg';
import { BackToTopButton } from "../../../components/BackToTopButton/BackToTopButton";
import ChangeLog from "../../../data/documentation/ChangeLog";
import { ChangeLogBox } from "./ChangeLogBox/ChangeLogBox";
import { Button, Tabs, TabPanel, ArrowIcon } from "@cmsgov/design-system";
import InpatientDataDictionary from "../../../data/documentation/InpatientDataDictionary";
import OutpatientDataDictionary from "../../../data/documentation/OutpatientDataDictionary";

interface ChangeLogEntry {
    summary: string,
    date: string,
    releaseDetails: string[]
}

interface DataDictionaryEntry {
    fieldName: string,
    dataType: string,
    definition: string[]
}

interface Props {
    type: string
}

interface State {
    changeLog: ChangeLogEntry[],
    changeLogState: boolean[],
    dataDictionaryType: string,
    baseUrl: string,
    dataDictionary: DataDictionaryEntry[]
    logExpanded: boolean
}

export class Body extends React.Component<Props, State> {
    constructor(Props: Props | Readonly<Props>) {
        super(Props);

        let defaultDataDictionaryType = "inpatient"

        this.state = {
            changeLog: ChangeLog,
            changeLogState: Array(ChangeLog.length).fill(false),
            dataDictionaryType: defaultDataDictionaryType,
            baseUrl: `${window.location.origin}`,
            dataDictionary: InpatientDataDictionary,
            logExpanded: false
        }
    }

    toggleChangeLogBox = (entryNumber: number, nextFocusElement: string) => {
        let newChangeLogState = this.state.changeLogState;
        newChangeLogState.forEach((entry, index) => {
            if (Number(index) === Number(entryNumber)) {
                newChangeLogState[index] = !entry;
            } else {
                newChangeLogState[index] = false;
            }
        })

        this.setState({ changeLogState: newChangeLogState })

        document.getElementById(nextFocusElement)?.focus();
    }

    toggleLogExpanded = () => {
        this.setState({ logExpanded: !this.state.logExpanded });
    }

    toggleDataDictionary = (dataType: string) => {
        let newDataDictionary;

        if (dataType === 'outpatient') {
            newDataDictionary = OutpatientDataDictionary
        } else {
            newDataDictionary = InpatientDataDictionary
        }

        this.setState({ dataDictionary: newDataDictionary, dataDictionaryType: dataType })
    }

    render(): JSX.Element {
        let DATA_DICTIONARY = this.state.dataDictionary.map((element) => (
            <div>
                <p>
                    <b tabIndex={0} role='heading' aria-level={1}>{element.fieldName}</b>
                    <div><b tabIndex={0} role='heading' aria-level={2}>Data Type: </b><span tabIndex={0}>{element.dataType}</span></div>
                    <ul role='list'>
                        {element.definition.map((entry) => (
                            <li role='listitem' aria-level={3} tabIndex={0} key={entry}>{entry}</li>
                        ))}
                    </ul>
                </p>

            </div>
        ));
        const LOG_EXPANDED = !this.state.logExpanded ? this.state.changeLog.filter((value, index) => index < 4) : this.state.changeLog;

        const CHANGE_LOG = LOG_EXPANDED.map((element, index) => (
            <div>
                <ChangeLogBox
                    summary={element.summary}
                    date={element.date}
                    releaseDetails={element.releaseDetails}
                    id={`${index}`}
                    onClick={this.toggleChangeLogBox}
                    isOpen={this.state.changeLogState[index]}
                />
                {
                    (index < this.state.changeLog.length - 1) ? (
                        <div className={"change-log-section-hr"} />
                    ) : null
                }

            </div>
        ));

        return (
            <div role="main" className="doc-body ds-u-padding-bottom--6">
                <section className="ds-l-container ds-base" role="application">
                    <div className="ds-l-row">
                    <article  id="main-content" className="ds-l-col--12 ds-l-sm-col--9">
                            {this.props.type === "dictionary" && (
                            <div>
                                <div className={"body-section-hr"}>
                                    <div className={`text`} tabIndex={0}>
                                        <Tabs onChange={(selectedId, prevSelectedId) => { this.toggleDataDictionary(selectedId) }}>
                                            <TabPanel
                                                key="inpatient"
                                                id="inpatient"
                                                tab="Inpatient"
                                                tabHref='#/Data_dictionary'
                                            >
                                                <div
                                                    className={"data-dictionary-entries ds-l-col--12 ds-l-sm-col--12"}>
                                                    {DATA_DICTIONARY}
                                                </div>
                                            </TabPanel>
                                            <TabPanel
                                                key="outpatient"
                                                id="outpatient"
                                                tab="Outpatient"
                                                tabHref='#/Data_dictionary'
                                            >
                                                <div
                                                    className={"data-dictionary-entries ds-l-col--12 ds-l-sm-col--12 ds-u-overflow--scroll"}>
                                                    {DATA_DICTIONARY}
                                                </div>
                                            </TabPanel>

                                        </Tabs>
                                    </div>

                                </div>
                            </div>)}
                            {this.props.type === "docs" && (<div>
                                <div className={"body-section-hr"}>
                                    <div className={`text`} tabIndex={0}>
                                        <p>Refer to our <a
                                            href={`${this.state.baseUrl}/swagger/v1/swagger.json`}>Swagger
                                            documentation</a> to learn how to consume our REST API. <br />
                                            Human-readable documentation for using the Export endpoints is also <a
                                                href={`${this.state.baseUrl}/UserGuideHistoricalPSFData.pdf`}>available
                                                here as PDF.</a>
                                        </p>
                                        <img src={APIEndpoint} alt={"API Endpoints"} />
                                    </div>
                                </div>
                            </div>)}
                            {this.props.type === "log" && (<div>
                                <div className={`doc-change-log ds-l-col--10 ds-l-sm-col--12`} tabIndex={0}>
                                    {CHANGE_LOG}
                                </div>

                                <button className="ds-c-button ds-c-button--transparent show-more-less" onClick={this.toggleLogExpanded}>
                                    {!this.state.logExpanded ? "Show More " : "Show Less "}
                                    {!this.state.logExpanded ? <ArrowIcon direction='down' /> : <ArrowIcon direction='up' />}
                                </button>
                            </div>)}
                        </article>
                    </div>
                </section>
                <BackToTopButton />
            </div>
        );
    }
}