import React from 'react';
import Logo from '../../images/footer-logo.png';
import './Footer.scss';

interface Props{}

interface State{
}

export class Footer extends React.Component<Props,State> {

    render(): JSX.Element {
        return (
            <div role='contentinfo' className="footer ds-u-padding-top--7">
                <div className={"ds-u-fill--white"}>
                    <hr className={"footer-line"} />
                </div>
                <div className={"footer-container"}>
                    <div className="ds-l-container">
                        <div className="ds-l-row footer-margin">
                            <div className="ds-l-col--12 ds-l-sm-col--6 ds-l-md-col--6 ds-u-margin-bottom--2">
                                <div className="ds-u-float--left ds-u-margin-right--2 logo-div">
                                    <a href="https://www.hhs.gov/" className='ds-u-display--inline-block' target="_blank"  rel="noopener noreferrer">
                                        <img src={Logo} className="logo-container " alt="CMS logo" />
                                    </a>
                                </div>
                                <p className={"ds-u-font-size--base focus"} tabIndex={0}>A federal government website managed and paid for by the U.S. Centers for Medicare & Medicaid Services.
                                    7500 Security Boulevard, Baltimore, MD 21244</p>
                            </div>
                            <div className="ds-l-col--12 ds-l-sm-col--6 ds-l-md-col--3 ds-u-margin-bottom--2">
                                <h6 className="ds-h4" tabIndex={0}>CMS & HHS Websites</h6>
                                <ul className="ds-c-list ds-c-list--bare ds-u-font-size--small">
                                    <li>
                                        <a href="https://www.cms.gov/About-CMS/Agency-Information/Aboutwebsite/Privacy-Policy.html">CMS Privacy Policy</a>
                                    </li>
                                    <li>
                                        <a href="https://www.cms.gov/">CMS.gov</a>
                                    </li>
                                    <li>
                                        <a href="https://www.medicare.gov/">Medicare.gov</a>
                                    </li>
                                    <li>
                                        <a href="https://www.mymedicare.gov/">MyMedicare.gov</a>
                                    </li>
                                    <li>
                                        <a href="https://www.medicaid.gov/">Medicaid.gov</a>
                                    </li>
                                    <li>
                                        <a href="https://www.healthcare.gov/">Healthcare.gov</a>
                                    </li>
                                    <li>
                                        <a href="https://www.hhs.gov/">HHS.gov</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="ds-l-col--12 ds-l-sm-col--6 ds-l-md-col--3 ds-u-margin-bottom--2">
                                <h4 className="ds-h4" tabIndex={0}>Additional Resources</h4>
                                <ul className="ds-c-list ds-c-list--bare ds-u-font-size--small">
                                    <li>
                                        <a href="https://design.cms.gov/">CMS Design System</a>
                                    </li>
                                    <li>
                                        <a href="https://www.foia.gov/">Freedom of Information Act</a>
                                    </li>
                                    <li>
                                        <a href="https://oig.hhs.gov/">Inspector General</a>
                                    </li>
                                    <li>
                                        <a href="https://www.cms.gov/About-CMS/Agency-Information/Aboutwebsite/NoFearAct.html">No Fear Act</a>
                                    </li>
                                    <li>
                                        <a href="https://www.medicare.gov/about-us/plain-writing">Plain Writing</a>
                                    </li>
                                    <li>
                                        <a href="https://www.usa.gov/">USA.gov</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;