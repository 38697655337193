import {Button} from '@cmsgov/design-system'
import InputField from '../../InputField/InputField'
import React from 'react'
import CreateLabel from '../InputLabel/InputLabel'

interface Props{
  inputId: string,
  label?: string,
  length?: number,
  onUpdate?: (id:string, value:string[], error?:string[], eventType?:string) =>  void,
  onError?: (errorMessage:string[])=>void,
  listItemLabel?: string,
  value?: string[],
  errorMessage?: string[],
  disabled?: boolean,
  maxItems?: number
}

interface State{}

export class InputList extends React.Component<Props, State> {
  props: any
  constructor (props: any) {
    super(props)
    this.state = {}
  }

  /**
   * Adds a field to the begining of the list of inputs
   */
  addValueField(): void {
    let values = [...(this.props.value || [''])]
    let errorMessages = [...(this.props.errorMessage || [''])]
    let targetIndex = 0
    values.push('')
    errorMessages.push('')
    targetIndex = values.length - 1
    setTimeout(() => {
      let element = document.getElementById(`${this.props.inputId}_${targetIndex}`)
      if(element !== null) element.focus()
    }, 50)
    this.updateState(values, errorMessages, undefined, this.props.onError)
  }

  /**
   * Removes the specified input field from the list of inputs. Keeps the ordering of the surrounding input fields
   * @param i 
   */
  removeValueField(i:number): void {
    if (this.props.value && this.props.value.length > 0) {
      const isLastField = this.props.value.length - 1 === i
      const lValues = this.props.value.slice(0, i)
      const rValues = this.props.value.slice(i + 1)
      let values = [...lValues, ...rValues]
      if(values.length === 0){
        values = ['']
      }

      let errorMessages:string[] = []
      if (this.props.errorMessage) {
        const lErrors = this.props.errorMessage.slice(0, i)
        const rErrors = this.props.errorMessage.slice(i + 1)
        errorMessages = [...lErrors, ...rErrors]
        if(errorMessages.length === 0){
          errorMessages = ['']
        }
      }

      if (isLastField) {
        const newIndex = i - 1 > 0 ? i - 1 : 0
        document.getElementById(`remove-${this.props.inputId}_${newIndex}`)!.focus()
      }
      this.updateState(values, errorMessages, undefined, this.props.onError)
    }
  }

  /**
   * Is the function run on updating any specific input field. Calls the rest of the update functionality
   * @param inputId 
   * @param value 
   * @param errorMessage 
   * @param eventType 
   */
  updateList(inputId:string, value:string, errorMessage:string, eventType:string): void {
    // Gathers the values and errors from the inputs, or defines them if there are none
    const values = this.props.value && this.props.value.length > 0 ? JSON.parse(JSON.stringify(this.props.value)) : [undefined]
    const errorMessages = this.props.errorMessage ? JSON.parse(JSON.stringify(this.props.errorMessage)) : values.map(() => false)
    const idx = inputId.split('_')[1]
    values[idx] = value
    errorMessages[idx] = errorMessage
    this.updateState(values, errorMessages, eventType, this.props.onError)
  }

  /**
   * Runs the update function passed in when any of the input fields have an updated value
   * @param values 
   * @param errorMessages 
   * @param eventType 
   * @param onError 
   */
  updateState(values:string[], errorMessages:string[], eventType?:string, onError?:Function): void {
    if (this.props.onUpdate) {
      this.props.onUpdate(this.props.inputId, values, errorMessages, eventType)
    }
    const hasError = errorMessages.reduce((isError, e) => isError || !!e, false)
    if (hasError && onError) {
      onError(errorMessages)
    }
  }

  render(): JSX.Element {
    const errorMessage = this.props.errorMessage ? this.props.errorMessage.reduce((hasError: any, e: any) => hasError || e, '') : undefined
    let valueList = this.props.value && this.props.value.length > 0 ? this.props.value : ['']
    return (
      <div>
        <label className="ds-c-label" htmlFor={this.props.inputId}>
          {CreateLabel(this.props)}
          {errorMessage && (
            <span className="ds-c-field__hint ds-u-color--error" id={`${this.props.inputId}-message`} role="alert">{errorMessage}</span>
          )}
        </label>
        <div className="dynamic-field-list">
          {valueList.map((field: any, idx: number) => {
            const subProps = JSON.parse(JSON.stringify(this.props))
            subProps.label = ''
            subProps.multiple = false
            subProps.required = false
            subProps.inputId = `${subProps.inputId}_${idx}`
            subProps.ariaLabel = `${this.props.label}, ${idx + 1}`
            subProps.value = field
            subProps.errorMessage = this.props.errorMessage && this.props.errorMessage[idx] ? ' ' : undefined
            subProps.onUpdate = (inputId: string, value: string, errorMessage: string, eventType: string) => this.updateList(inputId, value, errorMessage, eventType)
            return (
              <div key={`${subProps.inputId}`} className="dynamic-field">
                {(this.props.listItemLabel === undefined || (this.props.listItemLabel && this.props.listItemLabel.length > 0)) && (
                  <span className="sublabel">
                    {`${this.props.listItemLabel || this.props.label} #${idx + 1}`}
                  </span>
                )}
                {React.createElement(InputField, subProps)}
                <Button
                  id={`remove-${this.props.inputId}_${idx}`}
                  variation="transparent"
                  className="material-icons delete-button"
                  onClick={() => this.removeValueField(idx)}
                  disabled={this.props.disabled}
                >clear
                  <span className="ds-u-visibility--screen-reader">{`button for ${subProps.ariaLabel}`}</span>
                </Button>
              </div>
            )
          })}
          {(!this.props.maxItems || !this.props.value || this.props.value.length < this.props.maxItems) && (
              <Button
                key={`add-${this.props.inputId}-${valueList.length + 1}`}
                id={`add-${this.props.inputId}`}
                variation="transparent"
                className="add-button ds-text"
                onClick={() => this.addValueField()}
                disabled={this.props.disabled}
              >
                <span className="material-icons">add_circle_outline</span>
                {`${this.props.listItemLabel || this.props.label} #${valueList.length + 1}`}
              </Button>
          )}
        </div>
      </div>
    )
  }
}

export default InputList
