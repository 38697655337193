const SupportSideNav = [
    {
        link: "#get-in-touch",
        text: "Get In Touch"
    },
    {
        link: "#FAQs",
        text: "FAQs"
    }
]

export default SupportSideNav;