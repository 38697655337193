import {Button, Dialog} from '@cmsgov/design-system';
import React from 'react';
import FocusLock from '../../../../components/FocusLock/FocusLock';
import './HelpDrawer.scss';

interface Props{
  size?: "narrow" | "wide" | "full"
}

interface State{
    showHelp: boolean,
}

class HelpDrawer extends React.Component<Props,State> {
  constructor (props: Props | Readonly<Props>) {
    super(props)

    this.state = {
      showHelp: false
    }
  }

  componentDidUpdate() {
    if (this.state.showHelp) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible"; // or restore the original value
    }
  }

  handleDrawerOpen() {
    this.setState({ showHelp: true });
  }

  handleKeyDrawerOpen(event: { key: string; }) {
      if (event.key === 'Enter') {
          this.setState({ showHelp: true });
      }
  }

  handleDrawerClose() {
    this.setState({ showHelp: false });
  }

  render(): JSX.Element{
    const heading = (
        <div className={"ds-u-padding--3"}>
          <p className={"ds-u-font-weight--bold"} tabIndex={0}>How to keep leading zeroes with CSV from dropping in Excel</p>
          <p className={"help-drawer-header-text"} tabIndex={1}>When opening a CSV file in Excel, leading zeroes are not formatted appropriately by default and Excel will drop leading zeroes from some columns. There are steps that can be taken to prevent the format change using Excel’s Text Import Wizard:</p>
        </div>);

    const closeButtonText = (
        <div className={"close-text ds-u-padding-right--1"}>
          Close
        </div>
    )
    
      return (
        <div className={`ds-base`} style={{display:"inline"}}>
            <Button
                className={`ds-c-button ds-c-button--transparent help-drawer-toggle`}
                onClick={() => this.handleDrawerOpen()}
                aria-label={`Learn how to fix this button press enter to expand`}
                aria-expanded={this.state.showHelp}>
                Learn how to fix this
            </Button>



        {this.state.showHelp && (
          <FocusLock isLocked={this.state.showHelp}>
            <Dialog
                getApplicationNode={() => document.getElementById('root')}
                size={"full"}
                onExit={() => this.handleDrawerClose()}
                closeButtonSize={"small"}
                closeButtonText={closeButtonText}
                heading={heading}
                headerClassName={"help-drawer-header ds-u-color--white "}
                className={"help-drawer"}
            >
              <div>
                <p className={"ds-u-fill--white"}>
                  <ol className="ds-c-list">
                    <li tabIndex={1}>Save the CSV file to your computer.</li>
                    <li tabIndex={2}>Right-click on the saved file and rename the file by adding .txt to the file extension.</li>
                    <li tabIndex={3}>Select “yes” when asked if you are sure you want to change the file name extension. (If on a Mac system, click “Use .txt”).</li>
                    <li tabIndex={4}>Open Excel. Select File {'>'} Open and Browse.</li>
                    <li tabIndex={5}>Select the file with the .txt file extension. You may need to change the file type to “all files” to locate. Open the file.</li>
                    <li tabIndex={6}>The Text Import Wizard will launch.</li>
                    <li tabIndex={7}>Click the "Delimited" radio button in the Text Import Wizard. Select Next.</li>
                    <li tabIndex={8}>Deselect the "Tab," and select "Comma" from the list. Click the "Next" button.</li>
                    <li tabIndex={9}>Select the first column in the wizard, scroll right to the last column, select shift, and click on the last column. This action will select all columns to ensure your changes apply to all the data.</li>
                    <li tabIndex={10}>Select the "Text" radio button</li>
                    <li tabIndex={11}>Click the "Finish" button. The file will now open in Excel with leading zeroes intact.</li>
                  </ol>
                </p>
              </div>
            </Dialog>
          </FocusLock>
        )}
    </div>
      )
    }
  }

export default HelpDrawer
