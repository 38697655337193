/* eslint-disable react/jsx-no-target-blank */
import { Button, CloseIcon, Dialog } from '@cmsgov/design-system'
import React from 'react'
import './ConfirmationModal.scss'

interface Props {
    children?: string[],
    className?: string,
    heading?: string,
    warningMsg?: string,
    text?: string,
    onConfirm?: Function,
    onCancel?: Function,
    variation?: "primary" | "danger" | "success" | "transparent",
    size?: "narrow" | "wide" | "full"
}

interface State { }

export class ConfirmationModal extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props)
        this.state = {}
    }

    render(): JSX.Element {

        const closeButtonText = (
            <div className={"close-text"}>
              Close
            </div>
        )
        return (
            <Dialog
                className={`modal ${this.props.className || ''}`}
                getApplicationNode={() => document.getElementById('root')}
                closeIcon={<CloseIcon  />}
                onExit={(event) => this.props.onCancel!(event)}
                closeButtonText={closeButtonText}
                actions={[
                    <Button
                        key="cancel"
                        id="modal-cancel-button"
                        onClick={(event) => this.props.onCancel!(event)}
                        className={"ds-u-margin-right--2"}
                    >
                        No
                    </Button>,
                    <Button
                        key="confirm"
                        id="modal-confirm-button"
                        onClick={(event) => this.props.onConfirm!(event)}

                    >
                        Yes
                    </Button>]}
                actionsClassName="ds-u-margin-top--2"
                size={this.props.size}
                underlayClickExits
                headerClassName={"dialog-header"}
            >
                <div>
                    {
                        !!this.props.warningMsg ? (
                            <div className="ds-c-alert ds-c-alert--warn">
                                <div className="ds-c-alert__body">
                                    <p className="ds-c-alert__text" aria-label={this.props.warningMsg} tabIndex={0}>
                                        {this.props.warningMsg}
                                    </p>
                                </div>
                            </div>
                        ) : null
                    }

                    <p className={"ds-u-font-weight--bold"} tabIndex={0}>{this.props.text}</p>
                </div>

            </Dialog>
        )
    }
}

export default ConfirmationModal
